import { useHistory } from "react-router";
import { version } from "../../config/main";

export const OutsideNavbar = () => {
    let history = useHistory();

    return(
        <div className="tm-section-texture">
            <div className="uk-navbar-container">
                <nav className="uk-navbar-container uk-width-expand" style={{background: 'linear-gradient(to left, #0f1419, #181c22)', position: 'relative', zIndex: 980}} uk-navbar="true">
                    <div className="uk-navbar-left uk-margin-medium-left">
                        <div className="uk-navbar-item uk-logo" style={{color: 'white'}}>
                            <i className="fas fa-cloud om-navigation uk-margin-small-right" style={{cursor: 'pointer'}} om-tooltip="down" aria-label="Términos y Conciciones" type="button"></i> 
                            <span onClick={() => history.push('/')} style={{cursor: 'pointer'}} om-tooltip="down" aria-label="Volver a la página principal">Orchestra Manager</span>&nbsp;
                            <sup className="uk-text-small uk-text-bold uk-text-primary" style={{cursor: 'default'}}>{version}</sup>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    )
}