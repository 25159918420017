import { useContext, useEffect, useRef, Fragment } from "react"
import { FormContext, FormProvider } from "../../contexts/FormProvider"
import { FlexContainer } from "../containers/FlexContainer"

export const SmartForm = ({ children, states = [], ...props }) => {
    return(
        <FormProvider value={states}>
            <form {...props}>
                {children}
            </form>
        </FormProvider>
    )
}

export const LoadingBox = ({ children, ready = true, expand = true, ...props }) => {
    const [loading = false, /*error = ''*/, success = false] = useContext(FormContext)

    return(!success && ready ?
        loading ?
            <div className={`info-box-alt message-box${expand?'-expand':''}`}>
                <b className={"cyan"}>Enviando Datos...</b>
                <div className="om-cycle-progress"></div>
            </div>
            :
            <FlexContainer className={"uk-animation-fade uk-animation-fast"} gap={"5px"} justify={"end"}>
                { children }
            </FlexContainer>
        : null
    )
}

export const LoadingButton = ({ children, ...props }) => {

    const ref = useRef()
    const [loading = false, /*error = ''*/, success = false] = useContext(FormContext)
    const className = props.className.split(" ").filter(elem => elem.startsWith("uk-button-"))

    useEffect(() => {
        if(ref.current) {
            if(loading) {
                ref.current.classList.remove(className.length === 1 ? className[0]:"")
                ref.current.classList.add('uk-button-secondary')
            } else {
                ref.current.classList.remove('uk-button-secondary')
                ref.current.classList.add(className.length === 1 ? className[0]:"")
            }
        }
    }, [loading, className])

    return(!success ? 
        <button ref={ref} {...props} disabled={loading || props.disabled}>
            {loading ? 
                <span>Procesando solicitud <i className="fa fa-sync-alt fa-spin"></i></span>
                :
                <span>{children}</span>
            }
        </button> : null
    )
}


export const Input = ({...props}) => {
    const ref = useRef()
    const [loading = false, error = '', success = false] = useContext(FormContext)

    const handleKeyPress = () => {
        if(ref.current.classList.contains('uk-form-danger'))
            ref.current.classList.remove('uk-form-danger')
    }

    useEffect(() => {
        if(error) {
            ref.current.classList.add('uk-form-danger')
        } else if(ref.current.classList.contains('uk-form-danger')){
            ref.current.classList.remove('uk-form-danger')
        }
    }, [error])

    return(
        <input ref={ref} onKeyPress={handleKeyPress} {...props} disabled={success || loading}/>
    )
}

export const TextArea = ({...props}) => {
    const ref = useRef()
    const [loading = false, error = '', success = false] = useContext(FormContext)

    const handleKeyPress = () => {
        if(ref.current.classList.contains('uk-form-danger'))
            ref.current.classList.remove('uk-form-danger')
    }

    useEffect(() => {
        if(error) {
            ref.current.classList.remove('uk-form-success')
            ref.current.classList.add('uk-form-danger')
        } else if(ref.current.classList.contains('uk-form-danger')){
            ref.current.classList.remove('uk-form-danger')
            ref.current.classList.add('uk-form-success')
        }
    }, [error])

    return(
        <textarea ref={ref} onKeyPress={handleKeyPress} {...props} disabled={success || loading}></textarea>
    )
}

export const Select = ({ children, ...props }) => {
    const [loading = false, /*error = ''*/, success = false] = useContext(FormContext)

    return(
        <select {...props} disabled={success || loading}>
            {children}
        </select>
    )
}

export const DefaultFooter = ({ onClose, notify, setNotify, cancelOnly = false }) => (
    <Fragment>
        {!cancelOnly && <div className="uk-position-bottom-left uk-margin uk-margin-medium-left">
            <label><input className="uk-checkbox" checked={notify} onChange={(e) => setNotify(e.target.checked)} name="notify" type="checkbox"/> Recordatorio por email</label>
        </div>}
        <button className="uk-button uk-button-default uk-modal-close" onClick={onClose} type="button"><i className="fa fa-times"></i> <span className="hide-s">Cancelar</span></button>
    </Fragment>
)