import React from 'react';

export const FormContext = React.createContext(null);

export const FormProvider = ({ children, ...props }) => {

	return (
		<FormContext.Provider {...props} displayName={"FormContext"}>
			{children}
		</FormContext.Provider>
	);
};