import { useContext } from "react"
import { ModalContext } from "../contexts/ModalContext"

export const useAsyncModal = (key, action, component, header) => {
    const [id, setId] = useContext(ModalContext)

    const dispatch = (id) => {
        if(id)
            setId({ id, key, action, component, header })
        else
            setId({ id: undefined, key, action, component, header })
    }

    return [id.id, dispatch]
}