import { version } from "../../config/main";

export const Footer = () => (
    <div className="uk-section-small">
        <div className="uk-container uk-container-expand uk-text-center uk-position-relative">
            <ul uk-margin="" className="uk-subnav tm-subnav uk-flex-inline uk-flex-center uk-margin-remove-bottom">
                <li className="uk-first-column"><span><i className="fas fa-cloud"></i> OM - Version {version} &copy; Ángel Rodríguez</span></li>
            </ul>
        </div>
    </div>
)