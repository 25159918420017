import React from 'react';

export const CalendarContext = React.createContext(null);

export const CalendarProvider = ({ value, children }) => {

	return (
		<CalendarContext.Provider value={value} displayName={"CalendarContext"}>
			{children}
		</CalendarContext.Provider>
	);
};