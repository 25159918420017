import { Fragment, useEffect } from "react"
import { fetchAnnounce, fetchAnnounces } from "../../actions/announces"
import { useAsyncReducer } from "../../hooks/useAsyncReducer"
import { useAuth } from "../../hooks/useAuth"
import { announcesInitialState, announcesReducer } from "../../reducers/announces"
import { ContentBox } from "../containers/ContentBox"
import { InsideContainer } from "../containers/InsideContainer"
import { Announce, ArchiveAnnounce, ArchivedAnnounce, CreateAnnounce, EditAnnounce, RemoveAnnounce, RestoreAnnounce } from "../views/Announce"
import { useStatus } from "../../hooks/useStatus"
import { Header } from "../extras/Header"
import { Calendar } from "../views/Calendar"
import { useAsyncModal } from "../../hooks/useAsyncModal"
import { useSyncModal } from "../../hooks/useSyncModal"

export const MainView = () => {
    const [user] = useAuth()
    const [{ loadStatus }, handleStatus] = useStatus()

    const [loading, state, dispatch] = useAsyncReducer(announcesReducer, announcesInitialState, handleStatus)

    useEffect(() => {
        document.title = `${user.orchestra.short}: OrchestraManager`
        if(!loadStatus)
            dispatch(fetchAnnounces(user.token))
    // eslint-disable-next-line
    }, [loadStatus])

    return(  
        <InsideContainer reducerContext={[loading, state, dispatch, user]}>
            <div className="uk-width-2-3@m om-padding-remove@s">
                <Announces error={state.error} loading={loading} announces={state.announces} system={state.system}/>
            </div>
            <div className="uk-width-1-3@m uk-text-center hide-s">
                <Calendar/>
                <p className="uk-text-justify uk-margin-right">Para obtener información sobre el planning de los días señalados, haz click sobre el el día en el calendario.</p>
                { state.archived && state.archived.length > 0 && <ArchivedAnnounces error={state.error} archived={state.archived}/>}
            </div>
        </InsideContainer>    
    )
}

const list = (announces, system) => {
    if(announces)
        return [...announces.sort((a, b) => a.when < b.when), ...system.map(elem => ({...elem, system: true}))]
    else
        return undefined
}

const ArchivedAnnounces = ({ archived, error }) => {
    const [open] = useSyncModal()

    const [,setId] = useAsyncModal('announce', fetchAnnounce, ArchivedAnnounce.Body, ArchivedAnnounce.Header)
    const restoreHandler = (id) => open(RestoreAnnounce.Header, RestoreAnnounce.Body, id)

    return(
        <Fragment>
            <h3><i className="fas fa-archive"></i> Anuncios Archivados</h3>
            <hr/>
            <div className="uk-container">
                <div className="uk-padding-small uk-overflow-auto uk-height-max-medium">
                    <ContentBox className={'om-list'} loading={false} eventHandlers={{onClick: setId, onRestore: restoreHandler}}
                        error={error} list={archived.sort((a, b) => a.when < b.when)} component={ArchivedAnnounce.Element} gap={'30px'}
                    />
                </div>
            </div>
            <p className="uk-text-justify uk-margin-right">Desplázate por la lista para encontrar el anuncio que estas buscando, los anuncios estan ordenados cronológicamente encontrándose arriba el anuncio archivado más reciente.</p>
        </Fragment>
    )
}

const Announces = ({ announces, system, error, loading }) => {
    const [open] = useSyncModal()
    const [,setId] = useAsyncModal('announce', fetchAnnounce, EditAnnounce.Body, EditAnnounce.Header)

    const createHandler = () => open(CreateAnnounce.Header, CreateAnnounce.Body)
    const editHandler = (id) => setId(id)
    const removeHandler = (id) => open(RemoveAnnounce.Header, RemoveAnnounce.Body, id)
    const archiveHandler = (id) => open(ArchiveAnnounce.Header, ArchiveAnnounce.Body, id)

    return(
        <Fragment>
            <Header onClick={createHandler} buttonIcon={"fa fa-bullhorn"} buttonText={"Publicar Anuncio"}>
                <i className="far fa-1x fa-newspaper"></i> <span className="hide-s">Tablón de </span>Anuncios
            </Header>
            <ContentBox loading={loading} error={error} emptyMessage={'No se han encontrado anuncios'} list={list(announces, system)}
                component={Announce} eventHandlers={{onEdit: editHandler, onRemove: removeHandler, onArchive: archiveHandler}}
            />
        </Fragment>
    )
}