import { cloneElement, useEffect, useState } from "react"
import { FlexContainer } from "./FlexContainer"
import { Loading } from "../static/Loading"
import { EmptyMessage } from "../static/Messages"
import { useAuth } from "../../hooks/useAuth"

const ListWrapper = ({ list, component : Component, className, eventHandlers, permissions, gap = '40px' }) => (
    <FlexContainer className={'uk-grid '+className} direction={'column'} gap={gap}>
        {list.map((elem, i) => <Component permissions={permissions} eventHandlers={eventHandlers} key={i} id={i} elem={elem}/>)}
    </FlexContainer>
)

/*
*   This function requires the array (list) to be undefined in the initial state.
*   Considering that, the loading state keeps true until data arrives and changes to [{},{}] or [].
*/ 
export const ContentBox = ({ className, loading, error, emptyMessage = '', gap, emptyComponent = undefined, list = undefined, component, children, state, small = false, eventHandlers }) => {
    const [loadingState, setLoadingState] = useState(true)
    const [user] = useAuth()

    useEffect(() => {
        if(list || state)
            setLoadingState(small ? loading : false) // Show loading state when component is first rendered.
    }, [loading, list, state, small])

    return(
        error ? 
        <EmptyMessage small={small} message={error}/> : (
            loadingState ? 
                <Loading large={false}/> : (
                !children ? (
                list.length > 0 ? 
                <ListWrapper permissions={user.permissions} className={className} gap={gap} list={list} eventHandlers={eventHandlers} component={component}/> : (emptyComponent ? emptyComponent: <EmptyMessage small={small} message={emptyMessage}/>)) : (
                    cloneElement(children, { elem: state })
                )
            )
        )
    )
}