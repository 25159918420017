export const peopleInitialState = { 
    missed: undefined,
    activity: undefined,
    error: undefined
 }

export function peopleReducer(state, action) {
    switch (action.type) {
        case 'FETCH':
            return {...state, missed: action.missed, activity: action.activity, error: undefined}
        case 'ERROR':
            return {...state, error: action.error, missed: [], activity: []};
        default:
            return {...state}
    }
}