import { useEffect } from "react";
import { Link } from "react-router-dom";
import { OutsideNavbar } from '../../extras/export'
import { OutsideContainer } from '../../containers/OutsideContainer'

export const AboutView = () => {

    useEffect(() => {
        document.title = "Sobre esta plataforma: OrchestraManager"
    }, [])

    return(
        <>
            <OutsideNavbar/>
            <OutsideContainer>
                <h2><i className="fas fa-info-circle"></i> Sobre esta aplicación</h2>
                <p>OrchestraManager es una <b>plataforma web</b>, adaptada para ser visualizada tanto en ordenadores, como en dispositivos móviles y tablets. El objetivo de esta es <b>ayudar a cualquier tipo de agrupación musical</b> en la mayoría de sus tareas administrativas, lo que viene siendo como un sistema de gestión interno avanzado.</p>
                <p>Esta aplicación tiene muchas funciones internas, que podemos dividir 6 secciones principales: <b>Sistema de tablón de anuncios</b> con avisos por correo electrónico, <b>Mensajería privada</b> entre usuarios de la misma agrupación, <b>Control de asistencia avanzado</b> para que los gestores puedan controlarlo facilmente desde un dispositivo móvil, <b>Programación de eventos y calendario</b> también con sistema de avisos por correo electrónico, <b>Almacenamiento de partituras</b> con accesos restringidos por usuario, y por último, <b>Galería de fotos y videos</b> donde los propios componentes pueden subir videos o fotos.</p>
                <p>Esta plataforma cuenta también con soporte para <b>menores de edad</b>, en OrchestraManager nos tomamos muy enserio las leyes de protección de datos y estamos <b>al día con la legislación</b> vigente.</p>
                <p>Para descubrir todas las funcionalidades de esta aplicación, existe una <b>prueba gratuita disponible</b>, claro que después del preriodo de prueba, seguir usándola <b>supondría un coste mensual</b> que se destina al mantenimiento del servidor donde reside la plataforma.</p>
                <p>El desarrollo de la aplicación "OrchestraManager" ha sido realizado por <b>Ángel Rodríguez</b>, conocido también como "<a href="https://smartnexus.me" target="_blank" rel="noreferrer">smartnexus</a>", que se inspiró en la aplicación de miConservatorio.</p>
                <div className="uk-text-center uk-margin-top" style={{lineHeight: 2}}>
                    <Link to="/">Página Principal</Link>
                </div>
            </OutsideContainer>
        </>
    )
}