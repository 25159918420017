import { useEffect, useRef, useState } from "react"
import { useAsyncState } from "../../hooks/useAsyncState"
import { useAuth } from "../../hooks/useAuth"
import { ContentBox } from "../containers/ContentBox"
import { useStatus } from "../../hooks/useStatus"

export const AsyncModal = ({ responseKey, action, triggerState, component: Component, children }) => {
    const [{ loadStatus, submitStatus }, handleStatus] = useStatus()
    const [user] = useAuth()
    const [loading, state, error, set] = useAsyncState(responseKey, handleStatus)
    const [open, setOpen] = useState()
    const [trigger, clearTrigger] = triggerState

    const sync = responseKey === undefined

    const eventHandler = ({ trigger: id }) => {
        if(!sync)
            set(action(user.token, id))
        setOpen(true)
    }

    const closeHandler = () => {
        setOpen(false)
        clearTrigger(undefined)
    }

    useEffect(() => {
        if(trigger && !loadStatus) /* When trigger is valid, no SERVER_ERROR */
            eventHandler({ trigger })
        // eslint-disable-next-line
    }, [trigger, loadStatus])
    
    return(
        <Modal status={loadStatus || submitStatus} height={'fit-content'} open={open} close={closeHandler}>
            <div className="uk-modal-header">
                <h2 className="uk-modal-title uk-text-left">{children}</h2>
            </div>
            {sync ? <Component onClose={closeHandler} modalState={open} id={trigger}/> :
            <ContentBox small={true} loading={loading} error={error} state={state}>
                <Component onClose={closeHandler}/>
            </ContentBox>}
        </Modal>
    )
}

export const Modal = ({children, root = false, required = false, expand = false, open, close = () => {}, height = '100%', status = false, ...props}) => {

    const [display, setDisplay] = useState(false)
    const [flag, setFlag] = useState(false)

    const [handler, setHandler] = useState(false)

    const wrapperRef = useRef(null);

    useEffect(() => {
        if(!open) {
            document.removeEventListener("mousedown", handleClickOutside, false);
            setFlag(false)
            setTimeout(() => setDisplay(false), 100)
        } else {
            document.addEventListener("mousedown", handleClickOutside, false);
            setDisplay(true)
            setTimeout(() => setFlag(true), 100);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside, false);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    useEffect(() => {
        if(handler && flag && !required && !status) { /* Closing when status modal is not openned */
            close()
            setHandler(false)
        } else if (handler)
            setHandler(false)
    // eslint-disable-next-line
    }, [handler])

    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setHandler(true)
        }
    }

    return(
        <div className={flag ? (expand ? 'uk-modal-container uk-modal uk-open' : 'uk-modal uk-open') : (expand ? 'uk-modal-container uk-modal' : 'uk-modal')} style={{display: display ? 'block' : 'none', zIndex: root ? '9999' : '1010'}}>
            <div ref={wrapperRef} className={'uk-modal-dialog'} style={{height: height}} {...props}>
                {children}
            </div>
        </div>
    )
}