import { Redirect, Route } from "react-router-dom"
import { useAuth } from "../../hooks/useAuth"
import { NotFoundView } from "../app/outside/NotFoundView"

export const PrivateRoute = ({ requiredRole = -1, component: Component, app, ...rest }) => {

    const [{auth, permissions}] = useAuth() 

    return(
        <Route {...rest} exact={true} render={routeProps => (
            !auth ? (
                <Redirect
                    to={{
                        pathname: "/",
                        state: { from: routeProps.location },
                    }}
                />
            ) : (
                parseInt(permissions) > requiredRole && requiredRole !== -1 ?
                    <NotFoundView/>
                    :
                    <Component app={app} {...routeProps}/>
            )
        )}/>
    )
}
