import { fetchWithTimeout, handleError, handleResponseError } from "./handlers";

export const fetchUser = (token, name) => {
    return async (dispatch) => {
        const res = await fetchWithTimeout(`/user/${name}`, {
            headers: { 'Authorization': 'Bearer ' + token },
        });
        if(res.ok) {
            const { response, data } = await res.json()
            if(response) dispatch({ type: 'FETCH', user: data })
            else dispatch(handleError(data))
        } else
            dispatch(handleResponseError(res))
    }
}

export const updateUser = (token, name, user) => {
    return async (dispatch) => {
        const res = await fetchWithTimeout(`/user/${name}`, {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
            body: JSON.stringify(user)
        })

        if(res.ok) {
            const { response, data } = await res.json()
            if(response) dispatch({ type: 'EDIT', user: data })
            else dispatch(handleError(data))
        } else
            dispatch(handleResponseError(res))
    }
}
