import React from 'react';

export const ModalContext = React.createContext(null);

export const ModalProvider = ({ children, ...props }) => {

	return (
		<ModalContext.Provider {...props} displayName={"ModalContext"}>
			{children}
		</ModalContext.Provider>
	);
};