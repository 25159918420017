export const toBase64 = (str) => {
    return window.btoa(unescape(encodeURIComponent(str)));
}

export const fromBase64 = (str) => {
    try {
        return decodeURIComponent(escape(window.atob(str)));   
    } catch (error) {
        return null
    }
}