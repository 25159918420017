import { getYears } from "../utils/calendar";

export const calendarInitialState = { 
    years: getYears(new Date()), 
    month: (new Date().getMonth()), 
    events: [],
    error: undefined
 }

export function calendarReducer(state, action) {
    switch (action.type) {
        case 'FETCH':
            return {...state, events: action.events, error: undefined}
        case 'SET_YEARS':
            return {...state, years: action.years, events: []};
        case 'SET_MONTH':
            return {...state, month: action.month, events: []};
        case 'INIT': 
            return { years: action.years, month: action.month, events: [] }
        case 'ERROR':
            return {...state, error: action.error, events: [] };
        default:
            return {...state}
    }
}