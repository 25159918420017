import { InsideContainer } from "../../containers/InsideContainer"
import { Header } from "../../extras/Header"
import { useAsyncReducer } from "../../../hooks/useAsyncReducer"
import { peopleInitialState, peopleReducer } from "../../../reducers/people"
import { Fragment, useEffect } from "react"
import { ContentBox } from "../../containers/ContentBox"
import { Activity, Miss } from "../../views/People"
import { fetchInfo } from "../../../actions/people"
import { useAuth } from "../../../hooks/useAuth"
import { useStatus } from "../../../hooks/useStatus"

export const PeopleView = () => {
    const [{ loadStatus }, handleStatus] = useStatus()
    const [loading, state, dispatch] = useAsyncReducer(peopleReducer, peopleInitialState, handleStatus)
    const [user] = useAuth()

    useEffect(() => {
        if(!loadStatus)
            dispatch(fetchInfo(user.token, user.username))
    //eslint-disable-next-line
    }, [loadStatus])

    return(
        <InsideContainer>
            <div className="uk-width-2-3@m om-padding-remove@s">
                <Header>
                    <i className="fa fa-1x fa-users"></i> Plantilla
                </Header>
                <div className="uk-padding-small">
                    {state?.missed?.length > 0 && <BadNews/>}
                    <ContentBox error={state.error} loading={loading} list={state?.missed} component={Miss} gap={'20px'} emptyComponent={<GoodNews/>}/>
                </div>
            </div>
            <div className="uk-width-1-3@m uk-text-center hide-s">
                <h3><i className="fas fa-coins"></i> Actividad reciente</h3>
                <hr/>
                {state.activity && <RecentActivity activity={state.activity} error={state.error}/>}
            </div>
        </InsideContainer>
    )
}

const RecentActivity = ({ activity, error }) => (
    <div className="uk-container">
        <div className="uk-padding-small uk-overflow-auto uk-height-max-medium">
            <ContentBox small={true} className={'om-list'} loading={false} error={error} gap={'30px'}
                list={activity.sort((a, b) => a.when < b.when)} component={Activity} emptyComponent={
                    <Fragment>
                        <p>Ups, parece que no has estado realizando nada sospechoso en estos últimos días.</p>
                        <span style={{fontSize: '5rem'}}>&#128064;</span>
                    </Fragment>
                }
            />
        </div>
    </div>
)

const GoodNews = () => (
    <Fragment>
        <h2 className="uk-text-center uk-text-bold">¡Tenemos buenas noticias para ti!</h2>
        <p className="uk-text-center uk-text-lead uk-margin-remove-top">Parece que tu historial de asistencia está limpio <i className="fas ok fa-check-circle"></i></p>
        <p className="uk-text-center uk-text-emphasis"><i className="far fa-grin-wink fa-5x"></i></p>
        <br/><hr/>
        <p className="uk-text-justify uk-text-lead uk-padding-small"><b>RECUERDA:</b> Un registro de asistencia impecable da muy buena imagen del propio músico, y pone de manifiesto el gran compromiso que establece esa persona con la agrupación en cuestión.</p>
    </Fragment>
)

const BadNews = () => (
    <Fragment>
        <h2 className="uk-text-center uk-text-bold">¡Me temo que traemos malas noticias!</h2>
        <p className="uk-text-center uk-text-lead uk-margin-remove-top">Parece que tu historial de asistencia no está limpio <i className="fas not fa-times-circle"></i></p>
        <hr className="mt-2"/>
        <div className="mb-2 uk-text-center">
            <h5 className="uk-text-muted"><u>Echa un vistazo a la lista de todas las faltas registradas:</u></h5>
        </div>
    </Fragment>
)