import { fetchWithTimeout, handleError, handleResponseError, handleStateError } from "./handlers"

export const performChangeYears = async (token, years) => {
    const res = await fetchWithTimeout('/calendar/change/years/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
        body: JSON.stringify({ years })
    })
    
    if(!res.ok)
        return new Promise((_, reject) => reject(handleStateError(res.status)))
    else
        return await res.json()
}

export const fetchEvents = (token, year, month) => {
    return async (dispatch) => {
        const res = await fetchWithTimeout(`/events/${month}/${year}`, {
            headers: { 'Authorization': 'Bearer ' + token },
        });
        if(res.ok) {
            const { response, data } = await res.json()
            if(response) dispatch({ type: 'FETCH', events: data })
            else dispatch(handleError(data))
        } else
            dispatch(handleResponseError(res))
    }
}

export const fetchEvent = (token, id) => {
    return async (dispatch) => {
        const res = await fetchWithTimeout(`/event/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token },
        });
        if(res.ok) {
            const { response, data } = await res.json()
            if(response) dispatch({ type: 'FETCH', event: data })
            else dispatch(handleError(data))
        } else
            dispatch(handleResponseError(res))
    }
}

export const changeYears = (years) => ({
    type: 'SET_YEARS',
    years
})

export const changeMonth = (month) => ({
    type: 'SET_MONTH',
    month
})

export const initCalendar = (years, month) => ({
    type: 'INIT',
    month, years
})