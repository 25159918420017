import { fetchWithTimeout, handleError, handleResponseError } from "./handlers";

export const fetchAnnounces = (token) => {
    return async (dispatch) => {
        const res = await fetchWithTimeout('/announces', {
            headers: { 'Authorization': 'Bearer ' + token },
        });
        if(res.ok) {
            const { response, data } = await res.json()
            if(response) dispatch({ type: 'FETCH', announces: data.announces || [], archived: data.archived || [], system: data.system || [] })
            else dispatch(handleError(data))
        } else
            dispatch(handleResponseError(res))
    }
}

export const fetchAnnounce = (token, id) => {
    return async (dispatch) => {
        const res = await fetchWithTimeout(`/announce/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token },
        });
        if(res.ok) {
            const { response, data } = await res.json()
            if(response) dispatch({ type: 'FETCH', announce: data.announce })
            else dispatch(handleError(data))
        } else
            dispatch(handleResponseError(res))
    }
}

export const editAnnounce = (token, id, announce, notify) => {
    return async (dispatch) => {
        const res = await fetchWithTimeout(`/announce/${id}`, {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
            body: JSON.stringify(announce)
        }, notify)

        if(res.ok) {
            const { response, data } = await res.json()
            if(response) dispatch({ type: 'EDIT', announce: data.announce })
            else dispatch(handleError(data))
        } else
            dispatch(handleResponseError(res))
    }
}

export const archiveAnnounce = (token, id, archived) => {
    return async (dispatch) => {
        const res = await fetchWithTimeout(`/announce/${id}`, {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
            body: JSON.stringify({ archived })
        })

        if(res.ok) {
            const { response, data } = await res.json()
            if(response) dispatch({ type: archived ? 'ARCHIVE' : 'RESTORE', announce: data.announce })
            else dispatch(handleError(data))
        } else
            dispatch(handleResponseError(res))
    }
}

export const removeAnnounce = (token, id) => {
    return async (dispatch) => {
        const res = await fetchWithTimeout(`/announce/${id}`, {
            method: 'DELETE',
            headers: { 'Authorization': 'Bearer ' + token },
        })

        if(res.ok) {
            const { response, data } = await res.json()
            if(response) dispatch({ type: 'DELETE', announce: data.announce })
            else dispatch(handleError(data))
        } else
            dispatch(handleResponseError(res))
    }
}

export const createAnnounce = (token, announce, notify) => {
    return async (dispatch) => {
        const res = await fetchWithTimeout(`/announce`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
            body: JSON.stringify(announce)
        }, notify)

        if(res.ok) {
            const { response, data } = await res.json()
            if(response) dispatch({ type: 'CREATE', announce: data.announce })
            else dispatch(handleError(data))
        } else
            dispatch(handleResponseError(res))
    }
}
