import { useContext } from "react"
import { syncModal } from "../config/main"
import { ModalContext } from "../contexts/ModalContext"

export const useSyncModal = () => {
    const [,setId] = useContext(ModalContext)

    const dispatch = (header, component, id = syncModal) => {
        if(id)
            setId({ id, component, header })
        else
            setId({ id: undefined, component, header })
    }

    return [dispatch]
}