import { useEffect, useRef } from 'react'
import { fetchWithTimeout } from '../../actions/handlers'
import { Modal } from '../extras/export'
import { Disconnected } from '../views/Disconnected'
import { defaultStatus } from '../../hooks/useStatus'

export const ConnectionHandler = ({ error, onReconnect }) => {
    const { loadStatus, submitStatus } = error;
    const disconnected = loadStatus || submitStatus

    const ref = useRef()

    useEffect(() => {
        if(disconnected) {
            ref.current = setInterval(() => {
                fetchWithTimeout('/status').then(async res => {                    
                    if(res.ok) {
                        const { response } = await res.json()
                        if(response) {
                            clearInterval(ref.current);
                            onReconnect(defaultStatus)
                        }
                    }
                })
            }, 5000);
        }
        return () => {
            clearInterval(ref.current);
        }
    // eslint-disable-next-line
    }, [disconnected])

    return(disconnected ? 
        <Modal open={disconnected} required={true} className={'om-login'} root={true} height={'fit-content'}>
            <Disconnected/>
        </Modal>
    : null)
}