import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useHistory } from "react-router"

import { restore } from "../../../actions/authentication"
import { Input, LoadingButton, OutsideNavbar, SmartForm } from "../../extras/export"
import { OutsideContainer } from '../../containers/OutsideContainer'
import { FlexContainer } from "../../containers/FlexContainer"
import { ErrorMessage, SuccessMessage } from "../../static/Messages"
import { handleSubmitPromise } from "../../../utils/handleSubmitPromise"
import { useStatus } from "../../../hooks/useStatus"

export const RestoreView = () => {
    const history = useHistory()
    const [, handleStatus] = useStatus()
    
    const handleSubmit = (user, email) => new Promise((resolve, reject) => {
        restore(user, email).then(res => {
            if(res.response) {
                resolve(true)
            } else {
                reject(res.data);
            }
        }, handleStatus) /* Submit type, no args */
    })

    useEffect(() => {
        document.title = "Recuperar: OrchestraManager"
    }, [])

    const Form = ({ onSubmit }) =>  {
        const [err, setErr] = useState("")
        const [user, setUser] = useState("")
        const [email, setEmail] = useState("")
        const [loading, setLoading] = useState(false)
        const [success, setSuccess] = useState(false)
        
        const formValidation = (e) => {
            e.preventDefault()
            if(user && email) {
                handleSubmitPromise(onSubmit(user, email), setErr, setSuccess, setLoading);
            } else 
                setErr("Por favor complete todos los campos antes de enviar la solicitud");
        }

        return(
            <SmartForm onSubmit={formValidation} states={[loading, err, success]}>
                <div className="uk-margin">
                    <Input className="uk-input uk-margin-small-bottom" value={user} onChange={(e) => setUser(e.target.value)} type="text" placeholder="Introduce tu nombre de usuario asociado"/>
                    <Input className="uk-input" value={email} type="email" onChange={(e) => setEmail(e.target.value)} placeholder="Introduce tu correo electronico asociado"/>
                </div>
                <ErrorMessage err={err}/>
                <SuccessMessage onHide={() => history.push('/')} success={success}>Se ha enviado un correo con la información necesaria a <b className={'ok'}>{email}</b></SuccessMessage>
                <LoadingButton className={"uk-button uk-button-primary uk-width-expand"} type={"submit"}>
                    <i className="fab fa-telegram-plane"></i> Enviar Datos
                </LoadingButton>
            </SmartForm>
        )
    }

    return(
        <>
            <OutsideNavbar/>
            <OutsideContainer expand={false}>
                <h2><i className="fas fa-key"></i> Restaurar Contraseña</h2>
                <p className="uk-text-justify">Introduce el usuario y el correo electronico asociado a tu cuenta y te enviaremos instrucciones sobre como reestablecerla.</p>
                <div className="info-box-alt">
                    <p><u>Ejemplo de nombre de usuario</u>: <br/><b>Ant</b>onio <b>Per</b>éz <b>Gon</b>zález <b>antpergon</b></p>
                </div>
                <Form onSubmit={handleSubmit}/>
                <div className="uk-text-center uk-margin-top" style={{lineHeight: '2'}}>
                    <FlexContainer direction={'column'}>
                        <Link className={"uk-link-heading"} to="/">Volver a la Página Principal</Link>
                    </FlexContainer>
                </div>
            </OutsideContainer>
        </>
    )
}