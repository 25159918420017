import React, { Fragment, useContext, useEffect, useState } from "react";
import { changeYears, performChangeYears } from "../../actions/calendar";
import { startYear } from "../../config/main";
import { handleSubmitPromise } from "../../utils/handleSubmitPromise";
import { CloseButton } from "./Buttons";
import { ErrorMessage, InfoMessage, SuccessMessage } from "./Messages";
import { LoadingBox, Select, SmartForm } from "../extras/SmartForm";
import { requestType, useStatus } from "../../hooks/useStatus"
import { CalendarContext } from "../../contexts/CalendarProvider";
import { calcYears } from "../../utils/calendar";
import { useAuth } from "../../hooks/useAuth";

const Form = ({ onSubmit, onClose, calendar, modalState }) => {
    const [selected, setSelected] = useState(calendar.years);

    const [err, setErr] = useState("");
    const [info, setInfo] = useState("");
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)

    useEffect(() => {
        if(modalState) {
            setErr("");setInfo("");setLoading(false);setSuccess(false);
        }
    }, [modalState])

    useEffect(() => {
        setSelected(calendar.years)
    }, [calendar])

    const warnYears = () => {
        setInfo("Todos los eventos y controles de asistencia que se muestren serán del año académico que selecciones.");
    }

    const formValidation = (e) => {
        e.preventDefault()
        if(selected !== calendar.years) {
            handleSubmitPromise(onSubmit(selected), setErr, setSuccess, setLoading)
        } else {
            setErr("Por favor seleccione un año diferente antes de enviar la solicitud");
        }
    }

    return(
        <SmartForm onSubmit={formValidation} states={[loading, err, success]}>
            <p><b className={"cyan"}>Selecciona un año académico de la lista:</b></p>
            <div className="uk-margin">
                <Select value={selected} onChange={(e) => setSelected(e.target.value)} className={"uk-select"} onMouseDown={warnYears}>
                {calcYears(startYear).map((year, i) => (
                    <option key={i} value={year}>{year.slice(0,4)}-{year.slice(-4)}</option>
                ))}
                </Select>
            </div>
            <InfoMessage info={info} important={true}/>
            <ErrorMessage err={err}/>
            <SuccessMessage onHide={onClose} expand={false} success={success.response}>¡Guardado correctamente!</SuccessMessage>
            <LoadingBox expand={false}>
                <button className="uk-button uk-button-default uk-modal-close" onClick={onClose} type="button"><i className="fa fa-times"></i> <span className="hide-s">Cancelar</span></button>
                <button className="uk-button uk-button-primary" type="submit" ><i className="fa fa-exchange-alt"></i> <span className="hide-s">Cambiar</span></button>
            </LoadingBox>
        </SmartForm>
    )
}

export const Years = ({ onClose, open }) => {
    const [, handleStatus] = useStatus()
    const [user] = useAuth()
    const [calendar, dispatch] = useContext(CalendarContext)

    const handleSubmit = (years) => new Promise((resolve, reject) => {
        // TODO: use dispatch function action instead of performing a asyncRequest and calling dispatch then.
        performChangeYears(user.token, years).then(res => {
            if(res.response) {
                resolve(res)
                dispatch(changeYears(res.years))
            }
            else
                reject(res.data)
        }, (e) => handleStatus(e, requestType.SUBMIT, reject))
        // Reject promise to stop loading, and reset form (5xx).
    })

    return(
        <Fragment>
            <div className={"uk-modal-header"}>
                <h2 className={"uk-modal-title"}><i className={"fas fa-1x fa-history"}></i> Cambiar año académico</h2>
            </div>
            <div className={"uk-modal-body"}>
                <div className={"om-body"}>
                    <CloseButton onClick={onClose}/>
                    <Form onSubmit={handleSubmit} modalState={open} calendar={calendar} onClose={onClose}/>
                </div>
            </div>
        </Fragment>
    )
}