export const announcesInitialState = {
    announces: undefined,
    archived: undefined,
    system: undefined,
    error: undefined
}

const error = undefined;

export function announcesReducer(state, action) {
    switch (action.type) {
        case 'FETCH':
            return {
                announces: action.announces, 
                system: action.system,
                archived: action.archived
            };
        case 'DELETE':
            return {...state, 
                announces: state.announces.filter(elem => elem.when !== action.announce.when), 
                error
            };
        case 'CREATE':
            return {...state, 
                announces: [...state.announces, action.announce], 
                error
            };
        case 'EDIT': 
            return {...state, 
                announces: [...state.announces.filter(elem => elem.when !== action.announce.when), action.announce],
                error
            }
        case 'ARCHIVE':
            return {...state, 
                announces: state.announces.filter(elem => elem.when !== action.announce.when), 
                archived: [...state.archived, ...state.announces.filter(elem => elem.when === action.announce.when)], 
                error
            };
        case 'RESTORE':
            return {...state, 
                archived: state.archived.filter(elem => elem.when !== action.announce.when), 
                announces: [...state.announces, ...state.archived.filter(elem => elem.when === action.announce.when)], 
                error
            };
        case 'ERROR':
            return {...state, error: action.error}
        default:
            return {...state}
    }
}