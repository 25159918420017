import { Fragment } from "react";
import { CloseButton } from "../static/Buttons";
import { FlexContainer } from "../containers/FlexContainer"

export const Logout = ({ onClose, onLogout }) => (
    <Fragment>
        <div className={"uk-modal-header"}>
            <h6 className={"uk-modal-title"}>¿Listo para salir?</h6>
        </div>
        <div className={"uk-modal-body om-body"}>
            <CloseButton onClick={onClose}/>
            <p>Selecciona "Salir" abajo, si de verdad estas listo para <b>terminar tu sesión</b> en OrchestraManager.</p>
            <FlexContainer gap={'5px'} justify={"right"} className={"uk-text-right"}>
                <button className={"uk-button uk-button-default uk-modal-close"} type="button" onClick={onClose}>No, tal vez más tarde</button>
                <button className={"uk-button uk-button-primary"} type="button" onClick={onLogout}>Si, quiero salir ahora</button>
            </FlexContainer>
        </div>
    </Fragment>
)