import { Footer } from "../extras/Footer"

export const OutsideContainer = ({children, expand = true}) => {
    return(
        <div className="main">
            <div className="uk-container">
                <div className={!expand ? "om-login lazy-load" : "om-login om-login-expand lazy-load"}>
                    {children}
                </div>
            </div>
            <Footer/>
        </div>
    )
}