import { fetchWithTimeout, handleError, handleResponseError } from "./handlers";

export const fetchInfo = (token, user) => {
    return async (dispatch) => {
        const res = await fetchWithTimeout(`/user/${user}/info`, {
            headers: { 'Authorization': 'Bearer ' + token },
        });
        if(res.ok) {
            const { response, data } = await res.json()
            if(response) dispatch({ type: 'FETCH', missed: data.missed, activity: data.activity })
            else dispatch(handleError(data))
        } else
            dispatch(handleResponseError(res))
    }
}