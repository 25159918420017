import { Header } from "../../extras/Header"
import { InsideContainer } from "../../containers/InsideContainer"
import { useAsyncState } from "../../../hooks/useAsyncState"
import { useEffect, useState } from "react"
import { fetchUser, updateUser } from "../../../actions/user"
import { useAuth } from "../../../hooks/useAuth"
import { ContentBox } from "../../containers/ContentBox"
import { Input, LoadingBox, SmartForm } from "../../extras/SmartForm"
import { ErrorMessage, SuccessMessage } from "../../static/Messages"
import { handleSubmitPromise } from "../../../utils/handleSubmitPromise"
import { useStatus } from "../../../hooks/useStatus"

export const ProfileView = () => {
    const [{ loadStatus }, handleStatus] = useStatus()
    const [loading, state, error, dispatch] = useAsyncState('user', handleStatus)
    const [user] = useAuth()

    useEffect(() => {
        if(!loadStatus)
            dispatch(fetchUser(user.token, user.username))
        //eslint-disable-next-line
    }, [loadStatus])

    const handleSubmit = (userData) => new Promise((resolve, reject) => {
        dispatch(updateUser(user.token, user.username, userData), resolve, reject)
    })

    return(
        <InsideContainer grid={false}>
            <div className="om-padding-remove@s uk-container">
                <Header>
                    <i className="fa fa-1x fa-user-edit"></i> Mi Perfil
                </Header>
                <ContentBox error={error} loading={loading} state={state ? {...state, name: user.name, surnames: user.surnames}:undefined}>
                    <ProfileForm onSubmit={handleSubmit}/>
                </ContentBox>
                <p className="uk-text-lead uk-text-justify hide-s"><b>RECUERDA</b>: Cualquier dato que sea incorrecto o ficticio conllevará a <b>desactivar automáticamente</b> tu cuenta debido a un <b>incumplimiento de los Términos y Condiciones</b> de OrchestraManager. Si cometes un error introduciendo cualquiera de tus datos podrás cambiarlo en cualquier momento.</p>
            </div>
        </InsideContainer>
    )
}

const ProfileForm = ({ elem: user, onSubmit }) => {
    const [phone1, setPhone1] = useState(user.phone1)
    const [phone2, setPhone2] = useState(user.phone2)
    const [email, setEmail] = useState(user.email)
    const [password, setPassword] = useState('examplePass')

    const [err, setErr] = useState("");
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)

    const changed = phone1 !== user.phone1 || phone2 !== user.phone2 || email !== user.email || password !== 'examplePass'

    const resetFields = () => {
        setPhone1(user.phone1)
        setPhone2(user.phone2)
        setEmail(user.email)
        setPassword('examplePass')
        setErr("")
    }

    const formValidation = (e) => {
        e.preventDefault()
        if(phone1.length > 0 && password.length > 0) {
            let data = {}
            if(password !== 'examplePass')
                data = { email, phone1, phone2, password }
            else 
                data = { email, phone1, phone2 }

            handleSubmitPromise(onSubmit(data), setErr, setSuccess, setLoading)
        } else
            setErr('Por favor complete todos los campos antes de enviar')
    }

    return(
        <SmartForm onSubmit={formValidation} states={[loading, err, success]}>
            <div className="uk-grid uk-grid-medium">
                <div className="uk-width-expand@m uk-width-1-1">
                    <p><strong>Nombre y Apellidos</strong>:</p>
                    <input className="uk-input" type="text" value={`${user.surnames}, ${user.name}`} readOnly={true} disabled/>
                    <div className="uk-text-muted uk-text-small uk-text-justify">Para cambiar tu nombre en la plataforma tendrás que solicitarlo al administrador de tu agrupación musical.</div>
                    <p><strong>Correo electrónico</strong>:</p>
                    <Input className="uk-input" id="email" type="text" onChange={(e) => setEmail(e.target.value)} value={`${user.email}`}/>
                    <div className="uk-text-muted uk-text-small uk-text-justify">Recuerda que tu email es la única forma para reestablecer tu contraseña en caso de olvido o pérdida.</div>
                </div>
                <div className="uk-width-1-4@m uk-width-1-1">
                    <p><strong>Usuario de acceso</strong>:</p>
                    <input className="uk-input" id="user" type="text" value={`${user.user}`} readOnly={true} disabled/>
                    <div className="uk-text-muted uk-text-small uk-text-justify">Tu nombre de usuario se autogenera con las tres primeras letras de tu nombre y apellidos.</div>
                    <p><strong>Telefono Fijo (Opc.)</strong>:</p>
                    <Input className="uk-input" id="phone2" type="text" onChange={(e) => setPhone2(e.target.value)} value={phone2}/>
                    <div className="uk-text-muted uk-text-small uk-text-justify">Los datos opcionales sólo serán visibles para el administrador de tu agrupación musical.</div>
                </div>
                <div className="uk-width-1-4@m uk-width-1-1">
                    <p><strong>Contraseña</strong>:</p>
                    <Input className="uk-input" id="password" type="password" onChange={(e) => setPassword(e.target.value)} value={password} onFocus={() => setPassword('')}/>
                    <div className="uk-text-muted uk-text-small uk-text-justify">Si dejas este campo tal y como está no se modificará tu clave de inicio de sesión.</div>
                    <p><strong>Telefono Móvil</strong>:</p>
                    <Input className="uk-input" id="phone1" type="text" onChange={(e) => setPhone1(e.target.value)} value={phone1}/>
                    <div className="uk-text-muted uk-text-small uk-text-justify">Es necesario aportar un número de móvil para cualquier consulta urgente de tu agrupación musical.</div>
                </div>
            </div>
            <div className="uk-margin-medium uk-padding-small">
                <ErrorMessage err={err}/>
                <SuccessMessage expand={false} onHide={() => {setSuccess("");setPassword('examplePass')}} success={success}>¡Guardado correctamente!</SuccessMessage>
                <LoadingBox expand={false} ready={changed}>
                    <button className="uk-button uk-button-default" onClick={resetFields} type={'reset'} style={{float: 'right'}}><i className="fas fa-undo"></i> Restaurar</button>
                    <button className="uk-button uk-button-primary" type="submit" style={{float: 'right'}}><i className="fas fa-save"></i> Guardar Datos</button>
                </LoadingBox>
            </div>
        </SmartForm>
    )
}