import { Fragment, useContext, useEffect, useRef, useState } from "react"
import { fetchEvent } from "../../actions/calendar";
import { CalendarContext } from "../../contexts/CalendarProvider"
import { useAsyncModal } from "../../hooks/useAsyncModal";
import { getCurrentMonth, getCurrentYear, getMonthDays } from "../../utils/calendar"
import { EmptyMessage } from "../static/Messages";
import { EventHeader, EventPreview } from "./Event";

const today = (month) => {
    if(new Date().getMonth() === month) return new Date().getDate();
    else return 0;
}

const Days = ({events, years, month, onDayClick}) => {
    const [list, setList] = useState([])
    const eventsList = useRef([])
    
    useEffect(() => {
        setList(getMonthDays(getCurrentYear(years, month), month))
        eventsList.current = events.map(elem => (
            new Date(elem.when).getDate()
        ));
    }, [events, years, month])

    return(
        list.map((week, i) => (
            <tr key={i}>
                {week.map((day, j) => (
                    day !== 0 ? (
                        eventsList.current.includes(day) ? 
                        <th om-tooltip="down" aria-label={`Visualizar eventos en el día ${day}`} key={j} onClick={() => onDayClick(events[eventsList.current.indexOf(day)])} className={`${today(month) <= day ? 'uk-background-primary':'uk-background-muted'} uk-text-center om-cursor`} style={{color: today(month) <= day ? 'white':'red'}}><b>{day}</b></th> :                             
                        <th key={j} style={{color: today(month) <= day ? 'black':''}}>{day}</th>
                    ) : <th key={j}></th>
                ))}
            </tr>
        ))
    )
}

export const Calendar = () => {
    const [calendar] = useContext(CalendarContext)
    const [,setId] = useAsyncModal('event', fetchEvent, EventPreview, EventHeader)

    const eventHandler = ({ uuid }) => {
        setId(uuid)
    }

    return(
        <Fragment>
            <h3><i className="fas fa-calendar-alt"></i> <span id="mes2">{getCurrentMonth(calendar.month)}</span> de {getCurrentYear(calendar.years, calendar.month)}</h3>
            <hr/>
            <div className="uk-padding-small">
                <div className="uk-container">
                    <div className="uk-card uk-box-shadow-medium uk-card-body uk-padding-small">
                        {!calendar.error ? 
                        <table className="uk-table uk-table-small uk-table-divider om-calendar" id="dataTable" cellSpacing="0">
                            <thead>
                                <tr className="om-text-black">
                                    <th className="uk-text-center"><b>L</b></th>
                                    <th className="uk-text-center"><b>M</b></th>
                                    <th className="uk-text-center"><b>X</b></th>
                                    <th className="uk-text-center"><b>J</b></th>
                                    <th className="uk-text-center"><b>V</b></th>
                                    <th className="uk-text-center"><b>S</b></th>
                                    <th className="uk-text-center"><b>D</b></th>
                                </tr>
                            </thead>
                            <tbody>
                                <Days onDayClick={eventHandler} years={calendar.years} month={calendar.month} events={calendar.events}/>
                            </tbody>
                        </table> : <EmptyMessage small={true} message={calendar.error}/>}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}