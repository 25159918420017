import { getFormatedTime, getMonthYear, getShortDate } from "../../utils/calendar"
import { getFormattedActivity } from "../../utils/user"

export const Miss = ({ elem }) => (
    <div className="om-event uk-grid-small uk-padding-small uk-grid" uk-grid="" style={{cursor: 'default', marginTop: '0', marginBottom: '0'}}>
        <div className="om-event-date om-people-date uk-width-1-6@l uk-width-1-6@m uk-first-column"><h1>{getMonthYear(elem.when)}</h1></div>
        <div className="om-event-title om-people-title uk-width-1-6@l uk-width-1-6@m"><h4>{elem.type.toUpperCase()}</h4><ul><li>{getShortDate(elem.when)} CEST</li></ul></div>
        <div className="om-event-icon uk-width-1-6@l uk-width-1-6@m"><i className="fas fa-2x fa-question-circle om-cursor om-navigation" uk-toggle="target: #info"></i></div>
        <div className="om-event-desc uk-width-expand"><p>{elem.loc}</p></div>
    </div>
)

export const Activity = ({ elem }) => (
    <div className="om-list-display uk-padding-small">
        <h6><b>{getFormattedActivity(elem.task)}</b></h6>
        <p><span>{getFormatedTime(elem.date)}</span></p>
    </div>
)