export const getFormattedActivity = (task) => {
    let response = ''

    switch(task) {
        case 'user.profile.edit':
            response = <span><i className="fas fa-user-edit cyan"></i> Modificaste tus datos personales</span>;
            break;
        case 'user.token.restore':
            response = <span><i className="fas fa-poll cyan"></i> Recuperaste tu contraseña de acceso</span>;
            break;
        case 'folder.files.render':
            response = <span><i className="fas fa-folder-open cyan"></i> Visualizaste una carpeta de imágenes</span>;
            break;
        case 'parts.files.render':
            response = <span><i className="fas fa-cloud-download-alt cyan"></i> Descargaste unas partituras para estudiar</span>;
            break;
        case 'poll.answer':
            response = <span><i className="fas fa-clipboard-list cyan"></i> Diste tu opinión en una encuesta</span>;
            break;
        case 'folder.files.download':
            response = <span><i className="fas fa-images cyan"></i> Descargaste imágenes de una carpeta</span>;
            break;
        default:
            response = null;
    }

    return response
}