import { useRef, useState, useEffect } from 'react';
import { Link } from "react-router-dom"
import { OutsideNavbar } from "../../extras/export"
import { OutsideContainer } from '../../containers/OutsideContainer';

export const BadRequestView = () => {
    const ref = useRef()
    const [door, setDoor] = useState(false);

    useEffect(() => {
        ref.current = setInterval(() => setDoor(!door), 1000);
        document.title = "Bad Request: OrchestraManager"

        return () => clearInterval(ref.current)
    }, [door])
    
    return(
        <>
            <OutsideNavbar/>
            <OutsideContainer expand={false}>
                <h2><i className="fas fa-times-circle error"></i> Error 400: Bad Request</h2>
                <div className="om-body">
                    <div id="gif">
                        {door ? 
                            <i className='fas fa-5x fa-door-closed'></i> : <i className='fas fa-5x fa-door-open'></i>
                        }
                    </div>
                    <p className="uk-text-justify">La petición no ha sido enviada de la manera que espera el servidor, <b><u>prueba a cerrar tu sesión</u></b> e inténtalo de nuevo más tarde.</p>
                    <hr/>
                    <div className="uk-text-center uk-margin-top" style={{lineHeight: 2}}>
                        <Link to="/">Página Principal</Link>
                    </div>
                </div>
            </OutsideContainer>
        </>
    )
}