import { useContext, useEffect } from "react";
import { check, logout } from "../actions/authentication";
// import Cookie from "js-cookie";
import { AuthContext } from "../contexts/AuthProvider";
import { fromBase64, toBase64 } from "../utils/base64";
import { handleSubmitPromise } from "../utils/handleSubmitPromise"

export const useAuth = (handler = false) => {
    const [user, setUser] = useContext(AuthContext);

    const set = (user) => {
        const newUser = {auth: true, username: user["username"], token: user["token"], permissions: user["permissions"], orchestra: user["orchestra"], name: user["name"], surnames: user["surnames"] || ""}
        setUser(newUser);
        localStorage.setItem("session", toBase64(JSON.stringify(newUser)));
        //Cookie.set("session", toBase64(JSON.stringify(newUser)), { secure: true });
    }

    const clear = () => {
        localStorage.removeItem("session");
        //Cookie.remove("session");
        setUser({ auth: false })
    }

    const performLogout = () => {
        clear()
        logout(user.token)
    }

    const test = (token) => new Promise((resolve, reject) => {
        check(token).then(res => {
            if(res.ok) {
                resolve(res)
            } else {
                reject(true) // When request ends with a ok: false means, JWT token is not verified. So we should destroy the session it.
            }
        }, () => resolve(true)) // When request ends with an connection error we should keep the user session (could be internet disconnected o server crashed).
    })

    useEffect(() => {
        if(handler) {
            const stored = localStorage.getItem("session"); /*Cookie.get("session");*/
            if (stored && fromBase64(stored)) {
                let sUser;
                try {
                    sUser = JSON.parse(fromBase64(stored)); // Check malformed JSON.
                } catch (error) {
                    clear()
                }
                
                if(sUser) {
                    const { username = undefined, token = undefined, permissions = undefined, orchestra = undefined, name = undefined, surnames = undefined} = sUser; // Avoid missing fields.
                    handleSubmitPromise(test(token), (error) => {
                        if(error)
                            clear()                 
                    }, ({years, month}) => setUser({auth: true, month, years, username, token, permissions, orchestra, name, surnames}), () => {});   
                } else
                    clear()
            } else {
                clear()
            }
        }
        // eslint-disable-next-line
    }, []);

    return [user, set, check, performLogout, setUser];
}