import { Fragment, useEffect, useRef, useState } from "react"
import { modules } from "../../utils/modules"
import { useHistory } from "react-router-dom"
import { FlexContainer } from "../containers/FlexContainer";
import { requestType, useStatus } from "../../hooks/useStatus";
import { fetchCover } from "../../actions/get";

const MobileNavigation = ({module, permissions}) => {
    const history = useHistory()
    const [open, setOpen] = useState(false)
    const wrapperRef = useRef()

    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target))
            setOpen(false)
    }

    useEffect(() => {
        if(!open) {
            document.removeEventListener("mousedown", handleClickOutside, false);
        } else {
            document.addEventListener("mousedown", handleClickOutside, false);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside, false);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    return(
        <FlexContainer className={'hide-l'} fill wrap={true} justify={'space-between'} gap={'30px 25px'}>
            <button className="uk-button om-dropdown-button uk-button-secondary" onClick={() => setOpen(!open)} type="button">{open?'Ocultar menú':'Ir a'} <i className={`fas fa-chevron-${open?'up':'down'}`}></i></button>
            <div ref={wrapperRef} className={`uk-dropdown om-dropdown ${open?'om-open':''}`}>
                <ul className="uk-nav uk-dropdown-nav">
                    {permissions > '1' && <li>
                        <span className={`fas fa-1x fa-address-book ${module === modules.PEOPLE ? "om-navigation-active" : ''}`}></span>
                        <span onClick={() => history.push('/people')} className="uk-button index uk-button-text">Plantilla</span>
                    </li>}
                    {permissions < '2' && <li>
                        <span className={`fas fa-1x fa-users-cog ${module === modules.MANAGE ? "om-navigation-active" : ''}`}></span>
                        <span onClick={() => history.push('/people/manage')} className="uk-button index uk-button-text">Gestión</span>
                    </li>}
                    {permissions < '2' && <li>
                        <span className={`fas fa-1x fa-user-edit ${module === modules.CONTROL ? "om-navigation-active" : ''}`}></span>
                        <span onClick={() => history.push('/people/control')} className="uk-button index uk-button-text">Asistencia</span>
                    </li>}
                    <li>
                        <span className={`fas fa-1x fa-calendar-alt ${module === modules.CALENDAR ? "om-navigation-active" : ''}`}></span>
                        <span onClick={() => history.push('/calendar')} className="uk-button index uk-button-text">Calendario</span>
                    </li>
                    <li>
                        <span className={`fas fa-1x fa-chart-pie ${module === modules.POLLS ? "om-navigation-active" : ''}`}></span>
                        <span onClick={() => history.push('/polls')} className="uk-button index uk-button-text">Encuestas</span>
                    </li>
                    <li>
                        <span className={`fas fa-1x fa-images ${module === modules.MEDIA ? "om-navigation-active" : ''}`}></span>
                        <span onClick={() => history.push('/media')} className="uk-button index uk-button-text">Galería</span>
                    </li>
                    <li>
                        <span className={`fas fa-1x fa-cloud-download-alt ${module === modules.PARTS ? "om-navigation-active" : ''}`}></span>
                        <span onClick={() => history.push('/parts')} className="uk-button index uk-button-text">Obras</span>
                    </li>
                    <li>
                        <span className={`fas fa-1x fa-user-edit ${module === modules.PROFILE ? "om-navigation-active" : ''}`}></span>
                        <span onClick={() => history.push('/profile')} className="uk-button index uk-button-text">Mi Perfil</span>
                    </li>
                </ul>
            </div>
        </FlexContainer>
    )
}

export const Navigation = ({ permissions, module, orchestra, token }) => {
    const ref = useRef()
    const history = useHistory()
    const [cover, setCover] = useState(false)
    const [{ loadStatus }, handleStatus] = useStatus()

    useEffect(() => {
        if(!loadStatus && !cover) {
            fetchCover(token, orchestra.uuid).then(img => {
                let url = URL.createObjectURL(img);
                if(ref.current) {
                    ref.current.style.backgroundImage = `url(${url})`;
                    ref.current.style.display = 'block'
                    ref.current.classList.add('lazy-load')
                    setCover(true)
                }
            }, (r) => handleStatus(r, requestType.LOAD))
        }
        // eslint-disable-next-line
    }, [orchestra.uuid, loadStatus])

    return(
        <div ref={ref} className="uk-section uk-section-default uk-background-cover uk-background-center-center om-navigation-bar">
            <div className="uk-container uk-container-expand uk-position-relative">
                <div className="uk-margin-remove uk-padding-remove uk-background-cover om-bg-container uk-grid">
                    {module !== modules.INDEX ?
                        <div className="hide-s hide-m uk-align-center uk-margin-large-right uk-margin-large-left" style={{marginBottom: '0'}}>
                            <h1 className="uk-light uk-text-center" style={{fontSize: '5.5rem'}}><b>{orchestra.long}</b></h1>
                        </div>
                        :
                        <Fragment>
                            <div className="uk-width-1-4@l hide-s hide-m">
                                <div className="uk-card uk-card-default">
                                    <div className="uk-card-header">
                                        <h3 className="uk-card-title uk-margin-remove-bottom">¡Revisa tu nivel de asistencia!</h3>
                                    </div>
                                    <div className="uk-card-footer">
                                        <span onClick={() => history.push('/people')} className="uk-button index uk-button-text">Ir a Plantilla</span>
                                        <i className="fas fa-users fa-2x icon" style={{float: 'right'}}/>
                                    </div>
                                </div>
                            </div>
                            <div className="uk-width-1-4@l hide-m hide-s">
                                <div className="uk-card uk-card-default">
                                    <div className="uk-card-header">
                                        <h3 className="uk-card-title uk-margin-remove-bottom">¡Comparte tus recuerdos!</h3>
                                    </div>
                                    <div className="uk-card-footer">
                                        <span onClick={() => history.push('/media')} className="uk-button index uk-button-text">Ir a Galería</span>
                                        <i className="fas fa-camera-retro fa-2x icon" style={{float: 'right'}}/>
                                    </div>
                                </div>
                            </div>
                            <div className="uk-width-1-4@l hide-m hide-s">
                                <div className="uk-card uk-card-default">
                                    <div className="uk-card-header">
                                        <h3 className="uk-card-title uk-margin-remove-bottom">¡Planifícate este próximo mes!</h3>
                                    </div>
                                    <div className="uk-card-footer">
                                        <span onClick={() => history.push('/calendar')} className="uk-button index uk-button-text">Ir a Calendario</span>
                                        <i className="fas fa-calendar-day fa-2x icon" style={{float: 'right'}}/>
                                    </div>
                                </div>
                            </div>
                            <div className="uk-width-1-4@l hide-m hide-s">
                                <div className="uk-card uk-card-default">
                                    <div className="uk-card-header">
                                        <h3 className="uk-card-title uk-margin-remove-bottom">¡Prueba a estudiar algo en casa!</h3>
                                    </div>
                                    <div className="uk-card-footer">
                                        <span onClick={() => history.push('/parts')} className="uk-button index uk-button-text">Ir a Obras</span>
                                        <i className="fas fa-file-pdf fa-2x icon" style={{float: 'right'}}/>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    }
                    <MobileNavigation module={module} permissions={permissions}/>
                </div>
            </div>
        </div>
    )
}
