import { Fragment, useState, useEffect, useContext } from "react";
import { archiveAnnounce, createAnnounce, editAnnounce, removeAnnounce } from "../../actions/announces";
import { ReducerContext } from "../../contexts/ReducerContext";
import { formatText, getFormatedTime, getLongDate } from "../../utils/calendar";
import { handleSubmitPromise } from "../../utils/handleSubmitPromise";
import { DefaultFooter, Input, LoadingBox, SmartForm, TextArea } from "../extras/SmartForm";
import { CloseButton } from "../static/Buttons";
import { ErrorMessage, InfoMessage, SuccessMessage } from "../static/Messages";

export const Announce = ({ elem: { title, desc, author, when, system = false }, id, permissions, eventHandlers }) => (
    <div className={`uk-card ${!(system) ?'uk-card-default':'uk-card-secondary'} uk-card-hover om-padding-remove-left@s`} style={{margin: '0 2%', boxShadow: id === 0 && '0 15px 25px rgba(0, 0, 0, 0.2)'}}>
        <div className="uk-card-body uk-padding-remove-bottom om-margin-right@s">
            <h3 className="uk-card-title">
                {id === 0 && !system && <div className="hide-m hide-l"><div className="uk-label om-blinking">Última hora</div><br/></div>}<b>{title}</b> &nbsp;
                {id === 0 && !system && <div className="uk-label om-blinking hide-s">Última hora</div>}</h3>
            <p className="uk-text-justify">{formatText(desc)}</p>
        </div>
        <hr className="uk-margin-remove-bottom"/>
        <div className="uk-card-footer">
            {system ? 
                <p><b>Anuncio oficial desde la plataforma de OrchestraManager</b></p>:
                <p>
                    <i className="fas fa-user-circle om-icon-1x"></i> <span className="om-vertical-center"><span className="hide-s">{author} -</span> {getFormatedTime(when)}</span>
                    {permissions < 3 && <span className="uk-align-right uk-margin-remove-bottom">
                        <span om-tooltip="down" aria-label="Editar Anuncio" onClick={() => eventHandlers.onEdit(when)}><i className="fa fa-pencil-alt om-navigation om-margin-xs"></i></span>
                        <span className="hide-s" onClick={() => eventHandlers.onArchive(when)} om-tooltip="down" aria-label="Archivar Anuncio"><i className="fa fa-archive uk-margin-small-right om-margin-xs om-navigation"></i></span>
                        <span onClick={() => eventHandlers.onRemove(when)} om-tooltip="down" aria-label="Borrar Anuncio"><i className="fa fa-trash om-navigation om-margin-xs"></i></span>
                    </span>}
                </p>
            }
        </div>
    </div>
)

export const ArchivedAnnounce = {
    Element: ({ elem, permissions, eventHandlers }) => (
        <div className="om-list-display uk-padding-small">
            <h6 className="om-cursor om-navigation" onClick={() => eventHandlers.onClick(elem.when)} om-tooltip="top" aria-label="Visualizar Anuncio"><b>{elem.title}</b></h6>
            <p><i className="fas fa-user-circle om-icon-1x"></i> <span className="om-vertical-center uk-text-center">{elem.author} - {getFormatedTime(elem.when)} {permissions < 3 && <span style={{verticalAlign: 'baseline'}}>- <span style={{verticalAlign: 'baseline'}} onClick={() => eventHandlers.onRestore(elem.when)} om-tooltip="down" aria-label="Restaurar Anuncio"><i className="fa fa-archive om-navigation"></i></span></span>}</span></p>
        </div>
    ),
    Header: () => (
        <Fragment><i className="fas fa-eye"></i> Visualizar anuncio archivado</Fragment>
    ),
    Body: ({ elem, onClose }) => (
        <div className={"uk-modal-body om-body uk-text-left"}>
            <CloseButton onClick={onClose}/>
            <h4 className="uk-margin-remove-top"><b className="title">{elem.title}</b></h4>
            <div className="desc uk-text-justify uk-overflow-auto uk-height-max-large">
                {formatText(elem.desc)}
            </div>
            <hr/>
            <p>
                <span style={{color: '#333'}}><i className="fas fa-calendar-day"></i> <b>Fecha</b></span> 
                <span className="date"> {getLongDate(elem.when)}</span>
                <span style={{float: 'right'}}>
                    <span style={{color: '#333'}}><i className="fas fa-user-tie"></i> <b>Autor</b></span> 
                    <span className="author"> {elem.author}</span>
                </span>
            </p>
        </div>
    )
}

export const RemoveAnnounce = {
    Header: () => (
        <Fragment><i className="fas fa-eraser"></i> Borrar Anuncio</Fragment>
    ),
    Body: ({ onClose, modalState, id }) => {
        const [,,dispatch, user] = useContext(ReducerContext)

        const handleSubmit = () => new Promise((resolve, reject) => {
            dispatch(removeAnnounce(user.token, id), resolve, reject)
        })

        return(
            <div className="uk-modal-body">
                <div className="om-body">
                    <ConfirmForm onClose={onClose} onSubmit={handleSubmit} modalState={modalState} buttonStyle={"danger"} buttonText={"Eliminar"} buttonIcon={"fa-trash"}>
                        <p className="uk-text-justify uk-margin-remove-top">¿De verdad desea borrar el anuncio seleccionado? Esta acción no puede ser revertida. Si comete un error, deberá crear el anuncio de nuevo.</p>
                    </ConfirmForm>
                </div>
            </div>
        )
    }
}

export const RestoreAnnounce = {
    Header: () => (
        <Fragment><i className="fas fa-box-open"></i> Restaurar Anuncio</Fragment>
    ),
    Body: ({ onClose, modalState, id }) => {
        const [,,dispatch, user] = useContext(ReducerContext)

        const handleSubmit = () => new Promise((resolve, reject) => {
            dispatch(archiveAnnounce(user.token, id, false), resolve, reject)
        })

        return(
            <div className="uk-modal-body">
                <div className="om-body">
                    <ConfirmForm onClose={onClose} onSubmit={handleSubmit} modalState={modalState} buttonText={"Restaurar"} buttonIcon={"fa-archive"}>
                    <p className="uk-text-justify uk-margin-remove-top">¿De verdad desea restaurar el anuncio seleccionado? Los anuncios no archivados son accesibles desde el tablón de anuncios.</p>
                    </ConfirmForm>
                </div>
            </div>
        )
    }
}

export const ArchiveAnnounce = {
    Header: () => (
        <Fragment><i className="fas fa-box"></i> Archivar Anuncio</Fragment>
    ),
    Body: ({ onClose, modalState, id }) => {
        const [,,dispatch, user] = useContext(ReducerContext)

        const handleSubmit = () => new Promise((resolve, reject) => {
            dispatch(archiveAnnounce(user.token, id, true), resolve, reject)
        })

        return(
            <div className="uk-modal-body">
                <div className="om-body">
                    <ConfirmForm onClose={onClose} onSubmit={handleSubmit} modalState={modalState} buttonText={"Archivar"} buttonIcon={"fa-archive"}>
                        <p className="uk-text-justify uk-margin-remove-top">¿De verdad desea archivar el anuncio seleccionado? Los anuncios archivados siguen siendo accesibles pero no se muestran en el tablón.</p>
                    </ConfirmForm>
                </div>
            </div>
        )
    }
}

export const CreateAnnounce = {
    Header: () => (
        <Fragment><i className="fas fa-pencil-ruler"></i> Añadir nuevo anuncio</Fragment>
    ),
    Body: ({ onClose, modalState }) => {
        const [,,dispatch, user] = useContext(ReducerContext)
        const handleSubmit = (announce, notify) => new Promise((resolve, reject) => {
            dispatch(createAnnounce(user.token, announce, notify), resolve, reject)
        })  

        return(
            <div className="uk-modal-body">
                <div className="om-body">
                    <CloseButton onClick={onClose}/>
                    <AnnounceForm onSubmit={handleSubmit} onClose={onClose} modalState={modalState}>
                        <button className="uk-button uk-button-primary" type="submit" ><i className="fa fa-bullhorn"></i> <span className="hide-s">Publicar</span></button>
                    </AnnounceForm>
                </div>
            </div>
        )
    }
}

export const EditAnnounce = {
    Header: () => (
        <Fragment><i className="fas fa-file-signature"></i> Editar anuncio existente</Fragment>
    ),
    Body: ({ onClose, elem }) => {
        const [,,dispatch, user] = useContext(ReducerContext)
        const handleSubmit = (announce) => new Promise((resolve, reject) => {
            dispatch(editAnnounce(user.token, elem.when, announce), resolve, reject)
        })  

        return(
            <div className="uk-modal-body">
                <div className="om-body">
                    <CloseButton onClick={onClose}/>
                    <AnnounceForm announce={elem} onSubmit={handleSubmit} onClose={onClose} modalState={elem}>
                        <button className="uk-button uk-button-primary" type="submit" ><i className="fa fa-pencil-alt"></i> <span className="hide-s">Editar</span></button>
                    </AnnounceForm>
                </div>
            </div>
        )
    }
}

const AnnounceForm = ({ onClose, modalState, announce, onSubmit = () => {}, children }) => {
    const [title, setTitle] = useState("")
    const [desc, setDesc] = useState("")
    const [notify, setNotify] = useState(false)

    const [err, setErr] = useState("");
    const [info, setInfo] = useState("");
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)

    useEffect(() => {
        if(modalState) {
            setErr("");setInfo("");setLoading(false);setSuccess(false);setNotify(false);
        }
    }, [modalState])

    useEffect(() => {
        if(announce && modalState) {
            setTitle(announce.title);setDesc(announce.desc);
        } else if(modalState) {
            setTitle("");setDesc("");
        }
    }, [announce, modalState])

    const formValidation = (e) => {
        e.preventDefault()
        if(title.length > 0 && desc.length > 0) 
            handleSubmitPromise(onSubmit({ title, desc }, notify), setErr, setSuccess, setLoading)
        else
            setErr('Por favor complete todos los campos antes de enviar')
    }

    return(
        <SmartForm onSubmit={formValidation} states={[loading, err, success]}>
            <div className="uk-grid-small uk-grid uk-margin-medium">
                <div className="uk-width-1-1@l">
                    <div className="uk-margin">
                        <Input className="uk-input title uk-width-expand" value={title} onChange={(e) => setTitle(e.target.value)} id="name" type="text" placeholder="Título del Anuncio" autoFocus/>
                    </div>
                    <TextArea className="uk-textarea desc" id="desc" value={desc} onChange={(e) => setDesc(e.target.value)} placeholder="Escriba aquí el contenido para publicar."></TextArea>
                    <p className="small text-muted text-justify mt-2"><u>Ejemplo de texto con énfasis</u>: *texto en negrita* = <b>texto en negrita</b>.</p>
                </div>
            </div>
            <InfoMessage info={info} important={true}/>
            <ErrorMessage err={err}/>
            <SuccessMessage onHide={onClose} expand={false} success={success}>¡Guardado correctamente!</SuccessMessage>
            <LoadingBox expand={false}>
                <DefaultFooter notify={notify} setNotify={setNotify} onClose={onClose}/>
                {children}
            </LoadingBox>
        </SmartForm>
    )
}

const ConfirmForm = ({ children, onClose, onSubmit, modalState, buttonText, buttonIcon, buttonStyle = 'primary' }) => {
    const [err, setErr] = useState("");
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)

    useEffect(() => {
        if(modalState) {
            setErr("");setLoading(false);setSuccess(false);
        }
    }, [modalState])

    const formSubmit = () => {
        handleSubmitPromise(onSubmit(), setErr, setSuccess, setLoading)
    }

    return(
        <SmartForm onSubmit={formSubmit} states={[loading, err, success]}>
            {children}
            <ErrorMessage err={err}/>
            <SuccessMessage onHide={onClose} expand={false} success={success}>¡Guardado correctamente!</SuccessMessage>
            <LoadingBox expand={false}>
                <DefaultFooter cancelOnly={true} onClose={onClose}/>
                <button className={`uk-button uk-button-${buttonStyle}`} onClick={formSubmit}><i className={`fa ${buttonIcon}`}></i> <span className="hide-s">{buttonText}</span></button>
            </LoadingBox>                    
        </SmartForm>
    )
}