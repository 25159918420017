import { useRef, useState, useEffect } from 'react';
import { Link } from "react-router-dom"
import { OutsideNavbar } from "../../extras/export"
import { OutsideContainer } from '../../containers/OutsideContainer';

export const NotFoundView = () => {
    const ref = useRef()
    const [link, setLink] = useState(false);

    useEffect(() => {
        ref.current = setInterval(() => setLink(!link), 1000);
        document.title = "Not Found: OrchestraManager"

        return () => clearInterval(ref.current)
    }, [link])

    return(
        <>
            <OutsideNavbar/>
            <OutsideContainer expand={false}>
                <h2><i className="fas fa-times-circle error"></i> Error 404: Not Found</h2>
                <div className="om-body">
                    <div id="gif">
                        {link ?
                            <i className='fas fa-5x fa-link'></i> : <i className='fas fa-5x fa-unlink'></i>
                        }
                    </div>
                    <p className="uk-text-justify">El servidor ha respondido con un código de error 404, lo que significa que <b><u>lo que estas buscando no tiene pinta de estar en este lugar</u></b>, puede que te hayas cometido algún tipo de error.</p>
                    <hr/>
                    <div className="uk-text-center uk-margin-top" style={{lineHeight: 2}}>
                        <Link to="/">Página Principal</Link>
                    </div>
                </div>
            </OutsideContainer>
        </>
    )
}