import { useEffect, useState } from 'react';
import '../styles/App.css';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import { AuthProvider } from '../contexts/AuthProvider';
import { StatusProvider } from '../contexts/StatusProvider';

import { HelpView } from './app/outside/HelpView';
import { AboutView } from './app/outside/AboutView';
import { LoginView } from './app/outside/LoginView';
import { RestoreView } from './app/outside/RestoreView';
import { PasswordView } from './app/outside/PasswordView';

import { Loader } from './extras/Loader';
import { useAuth } from '../hooks/useAuth';
import { ConnectionHandler } from './extras/ConnectionHandler';
import { PrivateRoute } from './extras/PrivateRoute';

import { NotFoundView } from './app/outside/NotFoundView';

import { CalendarView } from './app/calendar/CalendarView';
import { MainView } from './app/MainView';
import { MediaView } from './app/media/MediaView';
import { PartsView } from './app/parts/PartsView';
import { PollsView } from './app/polls/PollsView';
import { SettingsView } from './app/settings/SettingsView';
import { ManageView } from './app/people/ManageView';
import { ControlView } from './app/people/ControlView';
import { WalletView } from './app/people/WalletView';
import { PeopleView } from './app/people/PeopleView';
import { ProfileView } from './app/people/ProfileView';

import { CalendarProvider } from '../contexts/CalendarProvider';
import { calendarInitialState, calendarReducer } from '../reducers/calendar';
import { fetchEvents, initCalendar } from '../actions/calendar';
import { getCurrentYear } from '../utils/calendar';
import { useAsyncReducer } from '../hooks/useAsyncReducer';
import { ModalProvider } from '../contexts/ModalContext';
import { App } from './app/App';
import { defaultStatus } from '../hooks/useStatus';

const LoaderWrapper = () => {
	useAuth(true)

	return(
		<Loader/>
	)
}

const RedirectHandler = (props) => {
	const [user] = useAuth(false)

	return(
		user.initial ? <LoaderWrapper/> : (user.auth ?         
			<Redirect to={{pathname: props.location.state !== undefined ? props.location.state.from.pathname : "/main", state: { from: props.location }}}/>
		: <LoginView/>)
	)
}

const Root = () => {

	const [user, setUser] = useState({ initial: true, auth: false });
	const [status, setStatus] = useState(defaultStatus)
	const [modal, setModal] = useState({ id: undefined, component: () => <div/>, header: () => <div/>})

	const [, calendar, dispatch] = useAsyncReducer(calendarReducer, calendarInitialState, setStatus)

	useEffect(() => {
		if(user.years && user.month && calendar.years !== user.years && calendar.month !== user.month) {
			dispatch(initCalendar(user.years, user.month))
		}
		if(user.token && calendar.years && calendar.month && calendar.events.length === 0)
			dispatch(fetchEvents(user.token, getCurrentYear(calendar.years, calendar.month), calendar.month))
		// eslint-disable-next-line
	}, [user, calendar.years, calendar.month])

	return (window.screen.width >= 340 ?
		<AuthProvider session={[user, setUser]}>
			<StatusProvider value={[status, setStatus]}>
				<ConnectionHandler error={status} onReconnect={setStatus}/>
				<Router>
					<Switch>
						<Route exact={true} path={'/'} component={RedirectHandler}/>
						<Route exact={true} path={'/help'} component={HelpView}/>
						<Route exact={true} path={'/about'} component={AboutView}/>
						<Route exact={true} path={'/restore'} component={RestoreView}/>
						<Route exact={true} path={'/restore/:token'} render={() => <PasswordView type={'restore'}/>}/>
						<Route exact={true} path={'/activate/:token'} render={() => <PasswordView type={'activate'}/>}/>
						<CalendarProvider value={[calendar, dispatch]}>
							<ModalProvider value={[modal, setModal]}>
								<App>
									<PrivateRoute exact={true} path={'/main'} component={MainView}/>
									<PrivateRoute exact={true} path={'/calendar'} component={CalendarView}/>
									<PrivateRoute exact={true} path={'/media'} component={MediaView}/>
									<PrivateRoute exact={true} path={'/parts'} component={PartsView}/>
									<PrivateRoute exact={true} path={'/people'} component={PeopleView}/>
									<PrivateRoute requiredRole={1} exact={true} path={'/people/manage'} component={ManageView}/>
									<PrivateRoute requiredRole={2} exact={true} path={'/people/control'} component={ControlView}/>
									<PrivateRoute requiredRole={1} exact={true} path={'/people/wallet'} component={WalletView}/>
									<PrivateRoute exact={true} path={'/polls'} component={PollsView}/>
									<PrivateRoute requiredRole={1} exact={true} path={'/settings'} component={SettingsView}/>
									<PrivateRoute exact={true} path={'/profile'} component={ProfileView}/>
								</App>
							</ModalProvider>
						</CalendarProvider>
						<Route render={() => <NotFoundView/>}/>
					</Switch>
				</Router>
			</StatusProvider>
		</AuthProvider> : <Loader blocked/>
	);
}

export default Root;
