import { useContext, useEffect, useRef } from "react"
import { StatusContext } from "../contexts/StatusProvider"
import { SE } from "../config/main"

export const requestType = {
    LOAD: 'onload_request',
    SUBMIT: 'onsubmit_request'
}

export const defaultStatus = {
    loadStatus: false,
    submitStatus: false
}

export const typeDetector = (resolve, reject) => {
    /* DETECTION OF ON LOAD REQUESTS AND SUBMIT REQUESTS */
    let rt = requestType.LOAD;
    if(typeof resolve === 'function' && typeof reject === 'function')
        rt = requestType.SUBMIT;

    return rt
}

export const useStatus = () => {
    const [{loadStatus, submitStatus}, setStatus] = useContext(StatusContext)
    const disconnected = loadStatus || submitStatus
    const cb = useRef()

    useEffect(() => {
        if(typeof cb.current === 'function' && !disconnected) {
            cb.current(SE)
            cb.current = null
        }
    }, [disconnected])

    const handleStatus = (error, type, callback) => {
        switch(type) {
            case requestType.LOAD:
                setStatus({ loadStatus: error, submitStatus })
                console.warn(requestType.LOAD, 'failed.')
                break;
            default:
                setStatus({ loadStatus, submitStatus: error })
                console.warn(requestType.SUBMIT, 'failed.')
                break;
        }
        cb.current = callback;
    }

    return [{loadStatus, submitStatus}, handleStatus]
}