import { Fragment, useEffect, useState, cloneElement, useRef } from "react";
import { useHistory } from "react-router";
import { version } from "../../config/main";
import { useAuth } from "../../hooks/useAuth";
import { modules } from "../../utils/modules";
import { Breadcums } from "../static/Breadcums";
import { Help } from "../views/Help";
import { Logout } from "../views/Logout";
import { Navigation } from "../static/Navigation";
import { Modal } from "./Modal";

const DropdownWrapper = ({ children, component, style }) => {
    const [open, setOpen] = useState(false)
    const timeout = useRef()

    const handleHover = () => {
        clearTimeout(timeout.current);
        timeout.current = setTimeout(() => setOpen(false), 500);
    }

    const hoverListener = (elem) => {
        if(elem.className !== component.props.className) {
            clearTimeout(timeout.current)
            setOpen(false)
        }
    }

    useEffect(() => {
        [].slice.call(document.getElementsByClassName("uk-navbar-item")).map(elem => elem.addEventListener('mouseenter', () => hoverListener(elem)))

        return () => {
            [].slice.call(document.getElementsByClassName("uk-navbar-item")).map(elem => elem.removeEventListener('mouseenter', () => hoverListener(elem)))
        }
    /*eslint-disable-next-line*/
    }, [])

    return(
        <Fragment>
            {cloneElement(component, { onMouseEnter: () => setOpen(true), onMouseLeave: handleHover})}
            {open && <div className={"uk-navbar-dropdown uk-drop uk-open uk-drop-boundary uk-animation-fade uk-animation-fast uk-drop-stack uk-drop-bottom-right"} style={style}>
                {cloneElement(children, { onMouseEnter: () => clearTimeout(timeout.current), onMouseLeave: handleHover })}
            </div>}
        </Fragment>
    )
}

export const InsideNavbar = ({ module }) => {
    let history = useHistory();
    const [user,,,logout] = useAuth()

    const [openLogout, setOpenLogout] = useState()
    const [openHelp, setOpenHelp] = useState()

    useEffect(() => {
        document.title = `${module || user.orchestra.short}: Orchestra Manager`
    }, [module, user])

    const handleLogout = () => setOpenLogout(true);
    const handleHelp = () => setOpenHelp(true);

    return(
        <>
            <Modal open={openLogout} close={() => setOpenLogout(false)} height={'fit-content'} children={<Logout onClose={() => setOpenLogout(false)} onLogout={logout}/>}/>
            <Modal open={openHelp} close={() => setOpenHelp(false)} height={'fit-content'} children={<Help onClose={() => setOpenHelp(false)}/>}/>
            <div className="uk-sticky uk-sticky-fixed" style={{position: 'fixed',top: '0px', width: '100vw'}} uk-sticky={"sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky; bottom: #transparent-sticky-navbar"}>
                <div className="uk-container uk-container-expand" style={{background: 'linear-gradient(to left, #0f1419, #181c22)'}}>
                    <nav className="om-nav-container uk-navbar" style={{background: 'linear-gradient(to left, #0f1419, #181c22)', position: 'relative', zIndex: '980'}}>
                        <div className="uk-navbar-left">
                            <div className="uk-navbar-item uk-logo" style={{paddingLeft: '12px', color: 'white'}}>
                                <i className="fas fa-cloud uk-margin-small-right" type="button"></i> 
                                <span om-tooltip="down" aria-label='Volver a la página principal' onClick={() => history.push('/main')} style={{cursor: 'pointer'}}>Orchestra Manager</span>
                                &nbsp;<sup className="uk-text-small uk-text-bold uk-text-primary" style={{cursor: 'default'}}>{version}</sup>
                            </div>
                        </div>
                        <div className="uk-navbar-right">
                            <ul className="hide-l uk-navbar-nav">
                                <li>
                                    <span className={"uk-navbar-item om-logout-btn"} onClick={logout} om-tooltip="down" aria-label="Cierra la sesión en la plataforma">
                                        <div className="uk-text-center">
                                            <i className="fas fa-sign-out-alt"></i>
                                            <div className="uk-navbar-subtitle">SALIR</div>
                                        </div>
                                    </span>
                                </li>
                            </ul>
                            <ul className="hide-s hide-m uk-navbar-nav">
                                <li className={module === modules.PEOPLE ? "uk-active" : ""}>
                                    {user.permissions < '2' ? 
                                    <DropdownWrapper style={{maxWidth: '150px'}} component={    
                                        <div className="uk-navbar-item uk-navbar-toggle animation uk-active om-people" style={{cursor: 'pointer'}}>
                                            <div className="uk-text-center" style={{color: (module === modules.MANAGE || module === modules.CONTROL || module === modules.WALLET ) && '#ddd'}}>
                                                <i className="fas fa-address-book"></i>
                                                <div className="uk-navbar-subtitle">PLANTILLA</div>
                                            </div>
                                        </div>
                                    }>
                                    <ul className="uk-nav">
                                        <li style={{margin: '0 0 0.5rem'}}><span className={"uk-link"} style={{textDecoration: 'none'}} onClick={() => history.push('/people/manage')} om-tooltip="right" aria-label="Gestiona tu agrupación"><i className="fas fa-users"></i> Gestión</span></li>
                                        <li style={{margin: '0.5rem 0'}}><span className={"uk-link"} style={{textDecoration: 'none'}} onClick={() => history.push('/people/control')} om-tooltip="right" aria-label="Realiza controles de asistencia"><i className="fas fa-edit"></i> Asistencia</span></li>
                                        <li style={{margin: '0.5rem 0 0'}}><span className={"uk-link"} style={{textDecoration: 'none'}} onClick={() => history.push('/people/wallet')} om-tooltip="right" aria-label="Organiza tus pagos a miembros"><i className="fas fa-piggy-bank"></i> Pagos</span></li>
                                    </ul>
                                    </DropdownWrapper> :
                                    <span className={"uk-navbar-item elem-people"} onClick={() => history.push('/people')} om-tooltip="down" aria-label="Organiza tus faltas de asistencia y gestiona tus datos personales">
                                        <div className="uk-text-center">
                                            <i className="fas fa-address-book"></i>
                                            <div className="uk-navbar-subtitle">PLANTILLA</div>
                                        </div>
                                    </span>
                                    }
                                </li>
                                <li className={module === modules.CALENDAR ? "uk-active" : ""}>
                                    <span className={"uk-navbar-item elem-calendar"} onClick={() => history.push('/calendar')} om-tooltip="down" aria-label="Visualiza eventos programados para este mes y hazte una idea">
                                        <div className="uk-text-center">
                                            <i className="fas fa-calendar-alt"></i>
                                            <div className="uk-navbar-subtitle">CALENDARIO</div>
                                        </div>
                                    </span>
                                </li>
                                <li className={module === modules.POLLS ? "uk-active" : ""}>
                                    <span className={"uk-navbar-item elem-polls"} onClick={() => history.push('/polls')} om-tooltip="down" aria-label="Comparte tus opiniones con los gestores de tu agrupación">
                                        <div className="uk-text-center">
                                            <i className="fas fa-chart-pie"></i>
                                            <div className="uk-navbar-subtitle">ENCUESTAS</div>
                                        </div>
                                    </span>
                                </li>
                                <li className={module === modules.MEDIA ? "uk-active" : ""}>
                                    <span className={"uk-navbar-item elem-media"} onClick={() => history.push('/media')} om-tooltip="down" aria-label="Accede a los recuerdos que has vivivo junto con tus compañeros">
                                        <div className="uk-text-center">
                                            <i className="fas fa-images"></i>
                                            <div className="uk-navbar-subtitle">GALERÍA</div>
                                        </div>
                                    </span>
                                </li>
                                <li className={module === modules.PARTS ? "uk-active" : ""}>
                                    <span className={"uk-navbar-item elem-parts"} onClick={() => history.push('/parts')} om-tooltip="down" aria-label="Descarga todas las partituras necesarias para poder practicar">
                                        <div className="uk-text-center">
                                            <i className="fas fa-cloud-download-alt"></i>
                                            <div className="uk-navbar-subtitle">OBRAS</div>
                                        </div>
                                    </span>
                                </li>
                                {user.permissions < '2' && 
                                <li className={module === modules.SETTINGS ? "uk-active" : ""}>
                                    <span className={"uk-navbar-item elem-settings"} onClick={() => history.push('/settings')} om-tooltip="down" aria-label="Modifica parámetros para sacar el máximo rendimiento posible">
                                        <div className="uk-text-center">
                                            <i className="fas fa-cog"></i>
                                            <div className="uk-navbar-subtitle">OPCIONES</div>
                                        </div>
                                    </span>
                                </li>}
                                <li>
                                    <div className="uk-navbar-item elem-separator" style={{cursor: 'default'}}>
                                        <span style={{fontSize: '2.25rem'}}>|</span>
                                    </div>
                                </li>
                                <li>
                                    <DropdownWrapper style={{maxWidth: '200px', right: '-5px'}} component={
                                        <div className="uk-navbar-item name uk-navbar-toggle">
                                            <div className="uk-text-center" style={{cursor: 'pointer'}}>
                                                <i className="fas fa-user-tie"></i>
                                                <div className="uk-navbar-subtitle">{user.name?.toUpperCase()}</div>
                                            </div>
                                        </div>
                                    }>
                                        <div>
                                            <div className="uk-text-center">
                                                <i className="fas fa-5x fa-user-circle"></i>
                                                <h5 style={{marginTop: '10%'}}>{user.name} {user.surnames && (' ' + user.surnames) }</h5>
                                            </div>
                                            <hr/>
                                            <ul className="uk-nav" style={{fontSize: '1rem', textAlign: 'right'}}>
                                                {user.permissions > '0' ? 
                                                <li style={{margin: '0.5rem 0'}}><span className={"uk-link"} style={{textDecoration: 'none'}} onClick={() => history.push('/profile')}><i className="fas fa-user-edit"></i> Mi Perfil</span></li> :
                                                <li style={{margin: '0.5rem 0'}}><span className={"uk-link"} style={{textDecoration: 'none'}} onClick={() => history.push('/dashboard')}><i className="fas fa-tools"></i> Dashboard</span></li>
                                                }
                                                <li style={{margin: '0.5rem 0'}} onClick={handleHelp}><span className="uk-nav uk-link" style={{textDecoration: 'none'}} uk-toggle={"target: #help"}><i className="fas fa-life-ring"></i> Ayuda</span></li>
                                                <li style={{margin: '0.5rem 0 0'}} onClick={handleLogout}><span className="uk-nav uk-link" style={{textDecoration: 'none'}} uk-toggle={"target: #logout"}><i className="fas fa-sign-out-alt"></i> Salir</span></li>
                                            </ul>
                                        </div> 
                                    </DropdownWrapper>                                
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
                <Breadcums user={user} title={module || modules.INDEX}/>
            </div>
            <div className="uk-sticky-placeholder" style={{height: '140px', margin: 0}}></div>
            <Navigation module={module || modules.INDEX} orchestra={user.orchestra} token={user.token} permissions={user.permissions}/>
        </>
    )
}