import { useState } from "react"
import { handleError } from "../actions/handlers"
import { typeDetector } from "./useStatus"

export const useAsyncState = (expectedObjectKey, handleStatus = () => {}, upsert = true) => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(undefined)
    const [error, setError] = useState(undefined)

    const setCallback = ({ type, ...rest}, resolve, reject) => {
        if(type === 'ERROR') {
            if(reject) reject(rest.error)
            else setError(rest.error)
            setLoading(false)
        } else if(type === 'SERVER_ERROR') {
            handleStatus(rest.error, typeDetector(resolve, reject), reject)
        } else {
            if(resolve) resolve(true)
            if(!resolve || upsert) setData(rest[expectedObjectKey])
            setLoading(false)
        }
    }

    const asyncSet = async (action, resolve = false, reject = false) => {
        setError(undefined)
        setLoading(true)
        action((action) => setCallback(action, resolve, reject)).catch(({ message = 'Error' }) => setCallback(handleError(message), resolve, reject))
    }

    /* If asyncSet includes "resolve, reject" the request will be processed as a SUBMIT type. */
    return [loading, data, error, asyncSet]
}