/* 5xx errors, failed connection or timeout */
const errorMapping = {
    "The operation was aborted. ": "El tiempo de respuesta para la conexión ha expirado",
    "500": "Se ha producido un error de conexión, inténtalo de nuevo.",
}

export const errorMapper = (key) => {
    const { code = 'Se ha producido un error inesperado'} = { code: errorMapping[key] }

    return code;
}

/* 2xx errors, backend responding but failed transaction */
const warningMapping = {
    "database_error": "Se ha producido un error con la base de datos."
}

export const warningMapper = (key) => {
    const { code = 'Se ha producido un error inesperado'} = { code: warningMapping[key] }

    return code;
}