import { useReducer, useState } from "react";
import { handleError } from "../actions/handlers";
import { typeDetector } from "./useStatus";

export const useAsyncReducer = (reducer, initialState, handleStatus) => {

    const [state, dispatch] = useReducer(reducer, initialState)
    const [loading, setLoading] = useState(false);

    const dispatchCallback = (action, resolve, reject) => {
        if(action.type === 'ERROR') {
            if(reject) reject(action.error)
            dispatch(action);
            setLoading(false);
        } else if(action.type === 'SERVER_ERROR') {
            handleStatus(action.error, typeDetector(resolve, reject), reject)
        } else {
            if(resolve) resolve(true)
            dispatch(action);
            setLoading(false);
        }
    }

    const asyncDispatch = async (action, resolve = false, reject = false) => {
        if(typeof action === 'object') 
            dispatch(action);
        else {
            action((action) => dispatchCallback(action, resolve, reject)).catch(({ message = 'Error' }) => dispatchCallback(handleError(message), resolve, reject))
            setLoading(true);
        }
    }
    
    /* If asyncDispatch includes "resolve, reject" the request will be processed as a SUBMIT type. */
    return [loading, state, asyncDispatch]
}
