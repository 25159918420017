import { Fragment } from "react"
import { useAuth } from "../../hooks/useAuth"

export const Header = ({ children, onClick = false, buttonIcon, buttonText }) => {
    const [user] = useAuth()
    return(
        <Fragment>
            <div id="header" className={'uk-grid uk-grid-stack'}>
                <div className="uk-width-expand@l om-page-title"><h2 className="om-header-text uk-padding-small">{children}</h2></div>
                {onClick && user.permissions < 3 && <div className="uk-width-1-6@l om-page-btn">
                    <button om-tooltip="down" aria-label={`${buttonText}`} className="uk-button uk-button-primary om-external-btn" onClick={onClick}><i className={`${buttonIcon}`}></i></button>
                </div>}
            </div>
            <hr/>
        </Fragment>
    )
}