import { Fragment } from "react";
import { CloseButton } from "../static/Buttons";

export const Help = ({ onClose }) => (
    <Fragment>
        <div className={"uk-modal-header"}>
            <h6 className={"uk-modal-title"}><i className={"fas fa-life-ring"}></i> Soporte de OrchestraManager</h6>
        </div>
        <div className={"uk-modal-body om-body"}>
            <CloseButton onClick={onClose}/>
            <p className={"uk-text-justify"}>Te recordamos que para realizar <b>cualquier consulta</b> sobre el uso y/o funcionamiento de la plataforma, existe un correo específico para ponerse en contacto.</p>
            <p className={"uk-text-justify"}>Mediante este correo se podrán enviar tanto <b>posibles fallos</b> de la plataforma, <b>ideas y/o sugerencias</b> para nuevas funcionalidades (El intervalo de respuesta es de 24 horas).</p>
            <h3 className={"uk-text-center"}><a href="mailto:support@orchestramanager.pw">support@orchestramanager.pw</a></h3>
        </div>
    </Fragment>
)