import { warningMapper } from "../config/error";
import { SE } from "../config/main";

export const handleSubmitPromise = (fn, se, ss, sl, cb = () => {}) => {
    sl(true);
    se("");
    fn.then(success => {
        ss(success);cb(success);
    }).catch(error => {
        if(error !== SE) {
            console.warn(error)
            se(warningMapper(error));
        }
    }).finally(() => {
        sl(false);
    })
}