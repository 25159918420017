import { useEffect } from "react"

export const EmptyMessage = ({ message, small = false }) => (
    <div className="uk-text-center uk-margin-top" id="list">
        <p className="uk-text-lead uk-padding-large hide-s" style={{paddingRight: small ? 'false':'5%'}}><i className="fas fa-times-circle not"></i> {message}.</p>
        <p className="uk-text-lead hide-m hide-l" style={{paddingRight: '5%'}}><i className="fas fa-times-circle not"></i> {message}.</p>
    </div>
)

export const ErrorMessage = ({err, expand = true}) => {
    return(err ? 
        <div className={`error message-box${expand?'-expand':''}`}>
            <div>{err}</div>
        </div> : null
    )
}
export const InfoMessage = ({info, important = false}) => {
    return(info ? 
        <div className="info" style={{margin: "1rem"}}>
            <div>{important && <b>AVISO:</b> }{info}</div>
        </div> : null
    )
}
export const SuccessMessage = ({ success, onHide, children, expand = true }) => {

    useEffect(() => {
        if(success)
            setTimeout(onHide, 3000);
    // eslint-disable-next-line
    }, [success]) 

    return(success ? 
        <div className={`ok-box-alt message-box${expand?'-expand':''}`}>
            <div>{children}</div>
            <div className="om-progress"></div>
        </div> : null
    )
    
}