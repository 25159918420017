import React from 'react';

export const AuthContext = React.createContext(null);

export const AuthProvider = ({ session, children }) => {

	return (
		<AuthContext.Provider value={session} displayName={"AuthContext"}>
			{children}
		</AuthContext.Provider>
	);
};