import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { checkOrchestra, getOrchestra, getOrchestras, login } from "../../../actions/authentication";
import { Input, LoadingButton, OutsideNavbar, Switch, Case, SmartForm, Select } from "../../extras/export";
import { ErrorMessage } from "../../static/Messages"
import { OutsideContainer } from '../../containers/OutsideContainer'
import { FlexContainer } from "../../containers/FlexContainer"
import { useAuth } from "../../../hooks/useAuth";
import { handleSubmitPromise } from "../../../utils/handleSubmitPromise";
import { requestType, useStatus } from "../../../hooks/useStatus";

export const LoginView = () => {
    const [,setAuth] = useAuth()
    const [orchestra, setOrchestra] = useState({long: undefined}); // Avoid flashing screens on first render.
    const [{ loadStatus }, handleStatus] = useStatus()

    const LoginForm = ({ onSubmit, orchestra }) => {
        const [user, setUser] = useState("");
        const [password, setPassword] = useState("");
        const [error, setError] = useState('');
        const [loading, setLoading] = useState(true);

        useEffect(() => {
            if(orchestra)
                setLoading(false)
        }, [orchestra])

        const formValidation = (e) => {
            e.preventDefault()
            if(user && password) {
                handleSubmitPromise(onSubmit(user, password), setError, setAuth, setLoading);
            } else 
                setError("Por favor complete todos los campos antes de enviar la solicitud");
        }

        return(
            <SmartForm onSubmit={formValidation} states={[loading, error]}>
                <div className="uk-margin">
                    <Input autoFocus className="uk-input uk-margin-small-bottom" value={user} onChange={(e) => setUser(e.target.value)} type="text" placeholder="Introduzca aquí su usuario"/>
                    <Input className="uk-input" value={password} onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Introduzca aquí su contraseña"/>
                </div>
                <ErrorMessage err={error}/>
                <LoadingButton className={"uk-button uk-button-success uk-width-expand"} type={"submit"}>
                    <i className="fas fa-user-check"></i> Inciar Sesión
                </LoadingButton>
            </SmartForm>
        )
    }

    const handleLogin = (user, password) => new Promise((resolve, reject) => {
        login(user, password, orchestra["uuid"]).then((res) => {
            if(res.response) {
                resolve({...res.user, orchestra})
            } else {
                reject(res.data)
            }
        }, handleStatus) /* Submit type, empty parameter list */
    })

    const SelectForm = ({ onSubmit }) => {
        const [orchestras, setOrchestras] = useState([]);
        const [selected, setSelected] = useState('default');
        const [loading, setLoading] = useState(false);
        const [err, setErr] = useState('')

        const formValidation = (e) => {
            e.preventDefault()
            handleSubmitPromise(onSubmit(selected), setErr, setOrchestra, setLoading)
        }

        useEffect(() => {
            if(!loadStatus)
                getOrchestras().then((data) => {
                    setSelected('default')
                    setOrchestras(data.orchestras);
                }, (r) => handleStatus(r, requestType.LOAD))
        // eslint-disable-next-line
        }, [loadStatus])

        return(
            <SmartForm onSubmit={formValidation} onKeyDown={formValidation} states={[loading]}>
                <h2><i className="fas fa-music"></i> Selección de agrupación</h2>
                <hr/><p>Bienvenido a Orchestra Manager, por favor seleccione una agrupación de la lista para iniciar sesión.</p><hr/>
                <div className="uk-margin">
                    <Select className="uk-select" defaultValue={"default"} value={selected} onChange={(e) => setSelected(e.target.value)} autoFocus={true}>
                        <option value={"default"} disabled>Por favor seleccione una agrupación</option>
                        {orchestras.map((orchestra, i) => (
                            <option key={i} value={orchestra.uuid}>{orchestra.long}</option>  
                        ))}
                    </Select>
                </div>
                <ErrorMessage err={err}/>
                <LoadingButton className={"uk-button uk-button-primary uk-width-expand"} type={'submit'} disabled={selected === "default"}>
                    <i className="fas fa-bolt"></i> Seleccionar agrupación
                </LoadingButton>
            </SmartForm>
        )
    }

    const handleSelect = (selected) => new Promise((resolve, reject) => {
        getOrchestra(selected).then((data) => {
            if(data.orchestra)
                resolve(data.orchestra);
            else
                reject("No se ha podido seleccionar esa orquesta.");
        }, handleStatus); /* Submit type */
    })

    useEffect(() => {
        if(!loadStatus) // Only request when status changes and is okey.
            checkOrchestra().then((data) => {
                setOrchestra(data.orchestra);
            }, (r) => handleStatus(r, requestType.LOAD));
    // eslint-disable-next-line
    }, [loadStatus])

    useEffect(() => {
        if(!orchestra) {
            document.title = "Switch: OrchestraManager"
        } else {
            document.title = "Login: OrchestraManager"
        }
    }, [orchestra])

    return(
        <>
            <OutsideNavbar/>
            <OutsideContainer expand={false}>
                <Switch condition={orchestra}>
                    <Case>
                        <h2><i className="fas fa-sign-in-alt"></i> Inicio de sesión</h2>
                        <hr/><p><b>{orchestra && (orchestra.long || "Cargando...")}</b></p><hr/>
                        <button className="uk-button uk-button-primary uk-align-center uk-margin-remove-top uk-button-small" onClick={() => setOrchestra(undefined)}><i className="fas fa-exchange-alt"></i> Cambiar de agrupación</button>
                        {orchestra && orchestra["login"] === "disabled" ?
                            <div className="error-box-alt">
                                <p className="uk-text-center"><i className="fas fa-times-circle not"></i> El inicio de sesión para esta agrupación se encuentra deshabilitado temporalmente.</p>
                                <h3 className="uk-text-justify uk-text-small">Para obtener más información póngase en contacto con el gestor de su agrupación.</h3>
                            </div>
                            :
                            <LoginForm onSubmit={handleLogin} orchestra={orchestra?.uuid}/>
                        }
                        <div className="uk-text-center uk-margin-top" style={{lineHeight: '2'}}>
                            <FlexContainer direction={'column'}>
                                <Link className={"uk-link-heading"} to="/restore">¿Ólvido la contraseña?</Link>
                                <Link className={"uk-link-heading"} to="/about">Información sobre Orchestra Manager</Link>
                            </FlexContainer>
                        </div>
                    </Case>
                    <Case>
                        <SelectForm onSubmit={handleSelect}/>
                        <div className="uk-text-center uk-margin-top" style={{lineHeight: '2'}}>
                            <FlexContainer direction={'column'}>
                                <Link className={"uk-link-heading"} to="/help">¿Necesita ayuda para usar la aplicación?</Link>
                                <Link className={"uk-link-heading"} to="/about">Información sobre Orchestra Manager</Link>
                            </FlexContainer>
                        </div>
                    </Case>
                </Switch>
            </OutsideContainer>
        </>
    )
}