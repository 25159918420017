import { useEffect } from "react"
import { Link } from "react-router-dom"
import { OutsideNavbar, Copyright } from '../../extras/export'
import { OutsideContainer } from '../../containers/OutsideContainer'

export const HelpView = () => {

    useEffect(() => {
        document.title = "Ayuda: OrchestraManager"
    }, [])

    return(
        <>
            <OutsideNavbar/>
            <OutsideContainer>
                <h2><i className="fas fa-question-circle"></i> Obtener Ayuda</h2>
                <p>Si su agrumación musical no se encuentra en la lista, puede enviar un correo al soporte de OrchestraManager (<a href="mailto:support@orchestramanager.pw">support@orchestramanager.pw</a>) para obtener información sobre la vigencia del contrato de su agrupación con la plataforma. En un plazo máximo de 24h obtendrá una respuesta.</p>
                <hr/>
                <div className="uk-align-center" style={{maxWidth: "843px"}}>
                    <h1><u>Tutoriales básicos sobre la plataforma:</u></h1>
                    <ul>
                        <li><h4>¿Cómo activar un usuario?</h4>
                        </li>
                    </ul>
                    <video className="uk-width-1-1" muted uk-video="autoplay: inview" controls>
                        <source src="/mov/activation.mp4" type="video/mp4"/>
                        El navegador no soporta la ventana de video.
                    </video>
                    <span style={{float: "left"}}><Copyright/></span>
                    <span style={{float: "right"}}><b>Colaboraciones</b>: Rodrigo Pacheco Aragón <b>(Voz)</b>.</span>
                </div>
                <div className="uk-text-center uk-margin-top" style={{lineHeight: 2}}>
                    <Link to="/">Página Principal</Link>
                </div>
            </OutsideContainer>
        </>
    )
}