import React from 'react';

export const ReducerContext = React.createContext(null);

export const ReducerProvider = ({ children, ...props }) => {

	return (
		<ReducerContext.Provider {...props} displayName={"ReducerContext"}>
			{children}
		</ReducerContext.Provider>
	);
};