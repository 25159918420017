import React from 'react';

export const StatusContext = React.createContext(null);

export const StatusProvider = ({ children, ...props }) => {

	return (
		<StatusContext.Provider {...props} displayName={"StatusContext"}>
			{children}
		</StatusContext.Provider>
	);
};