import parse from 'html-react-parser'

const getDaysInMonth = function(month,year) {
    return new Date(year, month+1, 0).getDate();
}  
const getWeekDay = function(day, month, year) {
    return new Date(year, month, day).getDay();
}

export const getLongDate = (when) => new Date(when).toLocaleDateString('es', {"weekday": "long", "year": "numeric","month": "long","day": "2-digit"})
export const getShortDate = (when) => new Date(when).toLocaleTimeString('es', {"minute": "2-digit","hour": "2-digit"})

export const getMonthDays = (month, year) => {
    let daysInMonth = getDaysInMonth(month, year);
    let monthDays = []
    
    const exitCond = (i, monthDays, daysInMonth) => (i > daysInMonth && monthDays.length % 7 === 0)
    
    let i = 1, j = 0;
    while(!exitCond(i,monthDays,daysInMonth)) {
        if(j < getWeekDay(0, month, year) || i > daysInMonth)
            monthDays.push(0);
        else {
            monthDays.push(i);
            i++;
        }
        j++;
    }
    
    let result = []
    while(monthDays.length !== 0) {
        result.push(monthDays.splice(0,7))
    }

    return result
}

export const formatText = (text) => {
    if(text) {
        let count = 0;

        while(text.indexOf("*") !== -1) { 
            if(count % 2 === 0) { 
                text = text.replace("*", "<b>") 
            } else { 
                text = text.replace("*", "</b>") 
            } 
            count += 1;
        }
        while(text.indexOf("\n") !== -1) {
            text = text.replace("\n", "<br/>")
        }
        return parse(text)
    } else return ''
}

export const getFormatedTime = (date) => {
    var seconds = Math.floor((Date.now() - date) / 1000), minutes = Math.floor(seconds/60), hours = Math.floor(seconds/3600),
        days = Math.floor(seconds/(3600*24)), months = Math.floor(seconds/(3600*24*30)), years = Math.floor(seconds/(3600*24*365));
    var result = '';
    if(seconds >= 0) {
        switch (seconds) {
            case 0:
                result = 'milésimas de segundo';
                break;
            case 1:
                result = seconds + ' segundo';
                break;
            default:
                result = seconds + ' segundos';
                break;
        }
    }
    if(minutes > 0) {
        result = (minutes===1)? minutes + ' minuto': minutes + ' minutos';
    }
    if(hours > 0) {
        result = (hours===1)? hours + ' hora': hours + ' horas';
    }
    if(days > 0) {
        result = (days===1)? days + ' día': days + ' días';
    }
    if(months > 0) {
        result = (months===1)? months + ' mes': months + ' meses';
    }
    if(years > 0) {
        result = (years===1)? years + ' año': years + ' años';
    }
    return 'hace ' + result;
}

export const getCurrentYear = (years, month) => {
    const first = parseInt(years.substring(0,4));
    const second = parseInt(years.substring(4,8));

    return ((month === 8 || month === 9 || month === 10 || month === 11)?first:second)
}

export const getCurrentMonth = (month) => {
    return new Date(`${month+1}/1/18`).toLocaleDateString('es', {"month": "long"})
}

export const getYears = (date) => {
    switch (date.getMonth().toString()) {
        case '8':
            return date.getFullYear() + '' + (date.getFullYear()+1);
        case '9':
            return date.getFullYear() + '' + (date.getFullYear()+1);
        case '10':
            return date.getFullYear() + '' + (date.getFullYear()+1);
        case '11':
            return date.getFullYear() + '' + (date.getFullYear()+1);
        default:
            return (date.getFullYear()-1) + '' + date.getFullYear();
    } 
}

export const calcYears = (startYear) => {
    const years_arr = [startYear];
    let date = new Date()
    let current_years = getYears(date)

    while(current_years !== startYear) {
        years_arr.push(current_years)
        date.setFullYear(date.getFullYear()-1);
        current_years = getYears(date);
    }
    years_arr.sort(function(a, b){return b-a})

    return years_arr
}

export const getMonthYear = (date) => {
    const d = new Date(date)
    return `${d.getDate()}/${(d.getMonth() + 1).toString().length === 1 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)}`
} 