import { fetchWithTimeout } from "./handlers"

export const check = async (token) => {
    const res = await fetchWithTimeout('/session', {
        headers: { 'Authorization': 'Bearer ' + token },
    })

    if(!res.ok)
        return new Promise((_, reject) => reject(res.status))
    else
        return await res.json()
}

export const logout = async (token) => {
    const res = await fetchWithTimeout('/logout', {
        headers: { 'Authorization': 'Bearer ' + token },
        method: 'POST',
    })

    if(!res.ok)
        return new Promise((_, reject) => reject(res.status))
    else
        return await res.json()
}

export const checkOrchestra = async () => {
    const res = await fetchWithTimeout('/check/orchestra')

    if(!res.ok)
        return new Promise((_, reject) => reject(res.status))
    else
        return await res.json()
}

export const getOrchestras = async () => {
    const res = await fetchWithTimeout('/orchestras');

    if(!res.ok)
        return new Promise((_, reject) => reject(res.status))
    else
        return await res.json()
}

export const getOrchestra = async (uuid) => {
    const res = await fetchWithTimeout('/orchestra/' + uuid);
    
    if(!res.ok)
        return new Promise((_, reject) => reject(res.status))
    else
        return await res.json()
}

export const restore = async (user, email) => {
    const res = await fetchWithTimeout('/restore', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ user, email })
    })
    
    if(!res.ok)
        return new Promise((_, reject) => reject(res.status))
    else
        return await res.json()
}

export const getRestore = async (token) => {
    const res = await fetchWithTimeout('/restore/' + token)
    
    if(!res.ok)
        return new Promise((_, reject) => reject(res.status))
    else
        return await res.json()
}

export const performRestore = async (token, pass) => {
    const res = await fetchWithTimeout('/restore/' +  token, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ pass })
    })
    
    if(!res.ok)
        return new Promise((_, reject) => reject(res.status))
    else
        return await res.json()
}

export const getActivation = async (token) => {
    const res = await fetchWithTimeout('/activate/' + token)
    
    if(!res.ok)
        return new Promise((_, reject) => reject(res.status))
    else
        return await res.json()
}

export const performActivation = async (token, pass) => {
    const res = await fetchWithTimeout('/activate/' +  token, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ pass })
    })
    
    if(!res.ok)
        return new Promise((_, reject) => reject(res.status))
    else
        return await res.json()
}

export const login = async (username, password, orchestra) => {
    const details = {
        username, password, orchestra
    };

    var formBody = [];
    for (var property in details) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    
    const res = await fetchWithTimeout('/login', 
    { 
        method: 'POST', 
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, 
        body: formBody
    });
    
    if(!res.ok)
        return new Promise((_, reject) => reject(res.status))
    else
        return await res.json()
}

