import { fetchWithTimeout, handleStateError } from "./handlers";

export const fetchCover = async (token, uuid) => {
    const res = await fetchWithTimeout(`/cover/${uuid}`, {
        headers: { 'Authorization': 'Bearer ' + token },
    })

    if(!res.ok)
    return new Promise((_, reject) => reject(handleStateError(res.status)))
else
    return await res.blob()
}