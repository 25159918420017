import { Fragment } from "react"
import { getLongDate } from "../../utils/calendar"
import { CloseButton } from "../static/Buttons"

export const EventPreview = ({elem, onClose}) => {
    return(
        <div className={"uk-modal-body om-body uk-text-left"}>
            <CloseButton onClick={onClose}/>
            <p className="uk-margin-remove-top">
                <span><i className="fas fa-tag"></i> <b>Tipo asignado</b></span> 
                <span className="type"> {elem.type}</span>
                <span style={{marginLeft: '3.5%'}}>
                    <span style={{color: '#333'}}><i className="fas fa-calendar-day"></i> <b>Fecha</b></span> 
                    <span className="date"> {getLongDate(elem.when)}</span>
                </span>
            </p>
            <p>
                <span><i className="fas fa-clipboard-list"></i> <b>Descripción o Motivo</b></span> 
                <span className="description"> {elem.description}</span>
            </p>
            <p>
                <span><i className="fas fa-user-clock"></i> <b>Hora de Citación</b></span> 
                <span className="time"> {new Date(elem.when).toLocaleTimeString('es', {"minute": "2-digit","hour": "2-digit"})} CEST</span>
                <span style={{marginLeft: '3.5%'}}>
                    <span style={{color: '#333'}}><i className="fas fa-business-time"></i> <b>Hora del Evento</b></span> 
                    <span className="plus"> {new Date(parseInt(elem.when)+parseInt(elem.plus)).toLocaleTimeString('es', {"minute": "2-digit","hour": "2-digit"})} CEST</span>
                </span>
            </p>
            <p>
                <span><i className="fas fa-map-marked-alt"></i> <b>Ubicación</b></span> 
                <span className="loc"> {elem.location}</span>
            </p>
            <p className="uk-margin-remove-bottom">
                <span style={{color: '#333'}}><i className="fas fa-music"></i> <b className="cyan">Programa</b></span>
            </p>
            <div className="schedule">{elem.schedule && elem.schedule.length > 0 ? 
                <ul className="om-text-black">
                    {elem.schedule.map((s,i) => (
                        <li key={i}> {s.name} - <span className="font-italic">{s.author}</span></li>
                    ))}
                </ul> : <p className="om-text-black uk-text-center">No se han encontrado datos.</p>
            }</div>
        </div>
    )
}

export const EventHeader = () => (
    <Fragment>
        <i className="fas fa-calendar-minus"></i> Información del Evento
    </Fragment>
)