import { Fragment, useContext, useState } from "react"
import { useHistory } from "react-router";
import { CalendarContext } from "../../contexts/CalendarProvider";
import { Modal } from "../extras/Modal";
import { Years } from "./Years";

export const Breadcums = ({ user, title }) => {

    const [openYears, setOpenYears] = useState(false)
    const [calendar] = useContext(CalendarContext)
    const history = useHistory()

    return(
        <Fragment>
            <div className={"uk-background-muted uk-card-default"} style={{padding: '0% 4%', position: 'relative', zIndex: 10}}>
                <ul className={"om-breadcrumb uk-breadcrumb"} style={{zIndex: 10}}>
                    <li><span onClick={() => history.push('/')} om-tooltip="down" aria-label="Ir a inicio"><i className={"fas fa-home om-navigation"}></i></span> { user.orchestra.short }</li>
                    { calendar.years && 
                        <li className={"hide-s years_b"}> 
                            {calendar.years.substring(0,4)}-{calendar.years.substring(4,8)}&nbsp;  
                            <span om-tooltip="down" aria-label="Cambiar curso escolar" onClick={() => setOpenYears(true)} style={{fontSize: '1.2rem', cursor: 'pointer'}}>&#127890;</span>
                        </li>
                    }
                    <li>{title===user.orchestra.short ? "Inicio" : title}</li>
                </ul>
            </div>
            <Modal open={openYears} close={() => setOpenYears(false)} children={<Years open={openYears} onClose={() => setOpenYears(false)}/>} height={"auto"}/>
        </Fragment>
    )
}
