import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router"
import { Link } from "react-router-dom"

import { getActivation, getRestore, performActivation, performRestore } from "../../../actions/authentication"
import { Input, LoadingButton, Modal, OutsideNavbar, Loader, SmartForm } from '../../extras/export'
import { OutsideContainer } from '../../containers/OutsideContainer'
import { FlexContainer } from "../../containers/FlexContainer"
import { ErrorMessage, SuccessMessage } from "../../static/Messages"

import { Terms } from "../../views/Terms"
import { NotFoundView } from "./NotFoundView"
import { BadRequestView } from "./BadRequestView"

import { useAuth } from "../../../hooks/useAuth"
import { handleSubmitPromise } from "../../../utils/handleSubmitPromise"
import { requestType, useStatus } from "../../../hooks/useStatus"

export const PasswordView = ({ type }) => {
    const { token } = useParams()
    const [session] = useAuth() 
    const [, handleStatus] = useStatus()
    const [user, setUser] = useState(false)
    const [loading, setLoading] = useState(true)
    const [open, setOpen] = useState(false)
    const history = useHistory()

    useEffect(() => {
        document.title = "Activar: OrchestraManager"
    }, [])

    const handleSubmit = (password) => new Promise((resolve, reject) => {
        if(type === "restore") 
            performRestore(token, password).then(res => {
                if(res.response) {
                    resolve(true)
                } else {
                    reject(res.data)
                }
            }, handleStatus); /* Submit type, no args */
        else
            performActivation(token, password).then(res => {
                if(res.response) {
                    resolve(true)
                } else {
                    reject(res.data)
                }
            }, handleStatus); /* Submit type, no args */
    })

    const Form = ({ onSubmit }) => {
        const [pass1, setPass1] = useState("");
        const [pass2, setPass2] = useState("");
        const [err, setErr] = useState("");
        const [loading, setLoading] = useState(false)
        const [success, setSuccess] = useState(false)

        const formValidation = (e) => {
            e.preventDefault()
            if(pass1 && pass2 && (pass1 === pass2)) {
                handleSubmitPromise(onSubmit(pass1), setErr, setSuccess, setLoading)
            } else if(pass1 && pass2) {
                setErr("Las contraseñas no coinciden");
            } else {
                setErr("Por favor complete todos los campos antes de enviar la solicitud");
            }
        }

        return(
            <SmartForm onSubmit={formValidation} states={[loading, err, success]}>
                <div className="uk-margin">
                    <Input className="uk-input uk-margin-small-bottom" value={pass1} onChange={(e) => setPass1(e.target.value)} type="password" placeholder="Escribe tu contraseña"/>
                    <Input className="uk-input" value={pass2} type="password" onChange={(e) => setPass2(e.target.value)} placeholder="Repite tu contraseña"/>
                </div>
                {type === "activate" && 
                <p className="uk-text-justify">Al activar tu cuenta <b>aceptas</b> los <b>Términos y condiciones</b> de OrchestraManager. <b className="om-cursor om-navigation" onClick={() => setOpen(true)} type="button">+info</b></p>
                }
                <ErrorMessage err={err}/>
                <SuccessMessage onHide={() => history.push('/')} success={success}>Se ha restablecido la contraseña correctamente</SuccessMessage>
                <LoadingButton className={"uk-button uk-button-primary uk-width-expand"} type={"submit"}>
                    <i className="fab fa-telegram-plane"></i> Enviar Datos
                </LoadingButton>
            </SmartForm>
        )
    }

    useEffect(() => {
        if(type === "restore") 
            getRestore(token).then(res => {
                setLoading(false)
                if(res.response) {
                    setUser(res.data.user);
                }
            }, (r) => handleStatus(r, requestType.LOAD)) /* Load type. */
        else 
            getActivation(token).then(res => {
                setLoading(false)
                if(res.response) {
                    setUser(res.data.user);
                }
            }, (r) => handleStatus(r, requestType.LOAD)) /* Load type */
    // eslint-disable-next-line
    }, [token])

    return(!session.auth ? <Loader loading={loading && !user}>{user ? 
        <>  
            <Modal open={open} required={type === 'activate'} children={<Terms submitClose={() => setOpen(false)}/>}/>
            <OutsideNavbar/>
            <OutsideContainer expand={false}>
                <h2><i className={"fas fa-" + (type === 'restore' ? 'key':'check-circle')}></i> { type === 'restore' ? 'Restaurar Contraseña' : 'Activar Cuenta'}</h2>
                <div className="info-box-alt">
                    <p>{user["surnames"]}, {user["name"]}</p>
                    <p>{user["email"]}</p>
                    <p>Usuario actual: <b>{user["username"]}</b></p>
                </div>
                <Form onSubmit={handleSubmit}/>
                <div className="uk-text-center uk-margin-top" style={{lineHeight: '2'}}>
                    <FlexContainer direction={'column'}>
                        <Link className={"uk-link-heading"} to="/">Volver a la Página Principal</Link>
                    </FlexContainer>
                </div>
            </OutsideContainer>
        </> : <NotFoundView/>}
        </Loader> : <BadRequestView/>
    )
}