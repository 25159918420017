import { useLocation } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { getModuleByPath } from "../../utils/modules";
import { InsideNavbar } from "../extras/InsideNavbar";

export const App = ({ children }) => {
    const [user] = useAuth()
    const location = useLocation();    

    return( 
        <div style={{background: 'white', height: '100%'}}>
            { !user.initial && user.auth && <InsideNavbar module={getModuleByPath(location.pathname)}/>}
            {children}
        </div>
    )
}
