import { errorMapper } from '../config/error'
import { api } from '../config/main'

export const handleResponseError = ({ status = 1, statusText = 'Error' }) => (
  	{ type: 'SERVER_ERROR', error: errorMapper(`${status}: ${statusText}`) }
)

export const handleError = (message = '') => (
  	{ type: 'ERROR', error: message }
)

export const handleStateError = (message = '') => (
    errorMapper(message)
)

const handleNotify = (notify) => notify ? '?notify=1' : ''

export async function fetchWithTimeout(resource, options = {}, notify = false) {
    const { timeout = 10000 } = options;
    
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    const response = await fetch(`${api}${resource}${handleNotify(notify)}`, {
      ...options,
      signal: controller.signal,
      credentials: "include" 
    });
    clearTimeout(id);
    return response;
}