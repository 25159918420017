import { Fragment, useContext, useState } from "react"
import { version } from "../../config/main"
import { ModalContext } from "../../contexts/ModalContext"
import { ReducerProvider } from "../../contexts/ReducerContext"
import { AsyncModal, Modal } from "../extras/Modal"
import { Terms } from "../views/Terms"

export const InsideContainer = ({ children, reducerContext = false, grid = true }) => {
    const [modal, setModal] = useContext(ModalContext)
    const [openTerms, setOpenTerms] = useState(false)
    return(
        <Fragment>
            <div className="uk-section uk-section-default uk-margin-remove-top" style={{paddingTop: '0px'}}>
                <div className="uk-container uk-container-expand uk-padding-remove om-body-container">
                    <hr className="uk-margin-remove-top"/>
                    <div className={`${grid ? 'uk-grid':''} uk-margin-small-top`}>
                        {reducerContext ? <ReducerProvider value={reducerContext}>
                            <AsyncModal triggerState={[modal.id, (id) => setModal({...modal, id})]} responseKey={modal.key} action={modal.action} component={modal.component}>
                                {modal.header()}
                            </AsyncModal>
                            {children}
                        </ReducerProvider>:children}
                    </div>
                </div>
            </div>
            <p className="uk-text-center uk-margin-medium-bottom" onClick={() => setOpenTerms(true)}><span><span style={{cursor: 'pointer'}} om-tooltip="down" aria-label="Términos y Condiciones"><i className="fas fa-cloud om-navigation"></i></span> OM - Version {version} &copy; Ángel Rodríguez</span></p>
            <Modal open={openTerms} expand={true} close={() => setOpenTerms(false)} children={<Terms/>}/>
        </Fragment>
    )
}