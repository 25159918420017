import { OutsideContainer } from "../containers/OutsideContainer"

export const Loader = ({ children = <div></div>, loading = true, blocked = false }) => {
    return (!blocked ?
        loading ?
        <div id="loader-wrapper" style={{position: 'fixed', top: 0, width: '100vw', height: '100vh', zIndex: '1000', background: 'linear-gradient(to left, #0f1419, #181c22)'}}>
            <div id="loader" className="uk-text-center" style={{marginTop: '25%'}}>
                <i className="fas zoom fa-cloud fa-4x" style={{color: 'white'}}></i>
            </div>
        </div> : children : <div id="loader-wrapper" style={{position: 'fixed', top: 0, width: '100vw', height: '100vh', zIndex: '1000', background: 'linear-gradient(to left, #0f1419, #181c22)'}}>
            <div id="loader" className="uk-text-center" style={{marginTop: '25%'}}>
                <i className="fas zoom fa-cloud fa-4x" style={{color: 'white', marginBottom: '10%'}}></i>
                <OutsideContainer>
                    <p>Tu dispositivo no es compatible con la aplicación de Orchestra Manager.</p> <p>Por favor ponte en contacto con el soporte.</p>
                </OutsideContainer>
            </div>
        </div>
    )   
}