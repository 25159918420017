export const modules = Object.freeze({
    INDEX: "Inicio",
    PEOPLE: "Plantilla",
    MANAGE: "Gestión",
    CONTROL: "Asistencia",
    WALLET: "Pagos",
    PROFILE: "Mi Perfil",
    DASHBOARD: "Dashboard",
    CALENDAR: "Calendario",
    POLLS: "Encuestas",
    MEDIA: "Galería",
    PARTS: "Obras",
    SETTINGS: "Opciones"
});

const modulesPath = Object.freeze({
    INDEX: "main",
    PEOPLE: "people",
    MANAGE: "people/manage",
    CONTROL: "people/control",
    WALLET: "people/wallet",
    PROFILE: "profile",
    DASHBOARD: "dashboard",
    CALENDAR: "calendar",
    POLLS: "polls",
    MEDIA: "media",
    PARTS: "parts",
    SETTINGS: "settings"
});

export const getModuleByPath = (path) => {
    const module = Object.keys(modules).find(key => modulesPath[key] === path.slice(1));
    return modules[module];
}